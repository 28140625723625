import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react"
import { useState } from "react";
import { AiOutlineLock } from "react-icons/ai";
import { BsCalendarWeek } from "react-icons/bs";
import { BsFillCreditCardFill } from "react-icons/bs";
import Cheque from "../../img/cheque.svg"
import cc from "../../img/cc.svg"
import { Input, Button, Typography, Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { paymentActions } from "../../_store";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import { Spinner } from 'flowbite-react';

const PlanModal = (props) => {

    const dispatch = useDispatch()

    // form validation rules 
    const validationSchema = Yup.object().shape({
        basic: Yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().required('Please fill Basic plan price'),
        premium: Yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('Please fill premium plan price'),
        monthtomonth: Yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('Please fill Month-to-Month plan price')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const submitAction = async (data) => {
        const payload = {
            basic: Number(data.basic),
            premium: Number(data.premium),
            monthtomonth: Number(data.monthtomonth),
        }
        console.log(payload)
        const resp = await dispatch(paymentActions.addPlan(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            props.setOpen(null)
        }
    }

    const common_style = "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"


    return <Dialog open={props.open} size={"sm"}>
            <DialogHeader>
                New Subscription Plan
            </DialogHeader>
            <DialogBody>
                <form>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-2 gap-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 pt-2">Basic Plan ($)</label>
                            <input {...register('basic')} className={common_style} type="number" />
                            <p className="text-red-600 mt-2 text-xs">{errors.basic?.message}</p>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 pt-2">Premium Plan ($)</label>
                            <input {...register('premium')} className={common_style} type="number" />
                            <p className="text-red-600 mt-2 text-xs">{errors.premium?.message}</p>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 pt-2">Month-to-Month Plan ($)</label>
                            <input {...register('monthtomonth')} className={common_style} type="number" />
                            <p className="text-red-600 mt-2 text-xs">{errors.monthtomonth?.message}</p>
                        </div>
                        <div className="grid grid-cols-3 gap-1 mt-4">
                            <Button variant="outlined" onClick={() => props.setOpen(null)} >
                                Cancel
                            </Button>
                            <Button className="col-span-2" onClick={handleSubmit(submitAction)}  size="lg">Add Subscription</Button>
                        </div>
                    </div>
                </form>
            </DialogBody>
        </Dialog>
}

export default PlanModal