import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import { membersActions } from './members.slice';
// create slice

const name = 'member';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });


// exports

export const memberActions = { ...slice.actions, ...extraActions };
export const memberReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        addMember: {},
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/member`;

    return {
        addMember: addMember(),
        deleteMember: deleteMember(),
        editMember: editMember()
    };    

    function addMember() {
        return createAsyncThunk(
            `${name}/addMember`,
            async (data, { dispatch }) => await fetchWrapper.post(baseUrl, data, () => dispatch(membersActions.members()), dispatch)
        );
    }

    function deleteMember() {
        return createAsyncThunk(
            `${name}/deleteMember`,
            async (data, { dispatch }) => await fetchWrapper.delete(baseUrl, data, () => dispatch(membersActions.members()), dispatch)
        );
    }

    function editMember() {
        return createAsyncThunk(
            `${name}/editMember`,
            async (data, { dispatch }) => await fetchWrapper.put(baseUrl, data, () => dispatch(membersActions.members()), dispatch)
            
        );
    }
}

function createExtraReducers() {
    return {
        ...addMember(),
        ...deleteMember(),
        ...editMember()
    };

    function addMember() {
        var { pending, fulfilled, rejected } = extraActions.addMember;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const addMember = action.payload;
                state.addMember = addMember;
                
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function deleteMember() {
        var { pending, fulfilled, rejected } = extraActions.deleteMember;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const deleteMember = action.payload;
                console.log('User Deleted')
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function editMember() {
        var { pending, fulfilled, rejected } = extraActions.editMember;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const editMember = action.payload;
                state.addMember = editMember;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
