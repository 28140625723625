import { Stepper, Step, Button, Select, Option, Checkbox, Dialog, List, ListItem, ListItemPrefix, Typography, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCard from "../../components/info-card/InfoCard";
import { ordersActions, utilityActions } from "../../_store";
import { conditions } from "../../_helpers";
import { HiMiniUserPlus } from "react-icons/hi2";
import { BsCartPlus } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { TbChecklist } from "react-icons/tb";
import PaymentMethods from "../payments/sections/PaymentMethods";
import EnrollmentSummary from "../../components/enrollment-summary/EnrollmentSummary";

const BookClass = (props) => {
    
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0)
    const [contract, setContract] = useState(3)
    const [error, setError] = useState(null)
    const [activeTermsCount, setActiveTermsCount] = useState(new Set())
    const [paymentTerms, setPaymentTerms] = useState(false)
    const [isLastStep, setIsLastStep] = useState(false)
    const [isFirstStep, setIsFirstStep] = useState(false)
    const [selectedIds, setSelectedIds] = useState(new Set())
    const [paymentKey, setPaymentKey] = useState(false)

    const { orderSummary: orderSummary, showEnrollModal: showEnrollModal } =  useSelector(state => state.orders)
    const {members: memberDetails} = useSelector(x => x.members)

    const closeModal = () => {
        dispatch(ordersActions.showEnrollModal(false))
        resetState()
    }
    
    const resetState = () => {
        setActiveStep(0)
        setActiveTermsCount([])
        setPaymentKey(null)
        setSelectedIds(new Set())
        setIsFirstStep(false)
        setIsLastStep(false)
    }

    useEffect(() => {
        return () => resetState()
    }, [])

    useEffect(() => {
        if(error) {
            console.log('Error Message')
            const errorPayload = {
                type: 'ERROR',
                message: error
            }
            dispatch(utilityActions.toast(errorPayload))
            dispatch(utilityActions.showToast(true))
        }
        
    }, [error])
    
    const steps = [
        {
            title: "Select students to enroll",
            buttonValue: "Next",
            errorMsg: "Required to select atleast one member",
            validation: () => selectedIds.size > 0,
            nextAction: () => console.log('Step 0'),
            prevAction: () => console.log('Step 0')
        },
        {
            title: "Terms & Conditions",
            buttonValue: "Next",
            errorMsg: "Require to accept all Terms & Condition",
            validation: () => activeTermsCount.size === 10,
            nextAction: () => console.log('Step 1'),
            prevAction: () => setSelectedIds(new Set())
        },
        {
            title: "Payment Options",
            buttonValue: "Next",
            errorMsg: "Require to accept the terms & Select a payment method",
            validation: () => !!paymentTerms && paymentKey,
            nextAction: async () => {
                const payload = {
                    "ids": [...selectedIds].map(ids => parseInt(ids)),
                    "paymentmethod_key": paymentKey
                }
                await dispatch(ordersActions.orderSummary(payload))
            },
            prevAction: () => setActiveTermsCount(new Set())
        },
        {
            title: "Summary",
            buttonValue: "Enroll",
            errorMsg: "Required to select atleast one member",
            validation: () => true,
            nextAction: async () => {
                const payload = {
                    "ids": [...selectedIds].map(ids => parseInt(ids)),
                    "paymentmethod_key": paymentKey,
                    "contract": parseInt(contract)
                }
                // if(payload.ids.length === 0 || !payload.paymentmethod_key) {
                //     return
                // }
                const resp = await dispatch(ordersActions.placeOrders(payload))
                if (resp.meta.requestStatus === 'fulfilled') {
                    props.closeModal()
                }
            },
            prevAction: () => console.log('Step 2')
        }
    ]

    const handleNext = async() => {
        if(!steps[activeStep].validation()) {
            setError(steps[activeStep].errorMsg)
            return
        }
        await steps[activeStep].nextAction()
        if (isLastStep) {
            return
        }
        setError(null)
        setActiveStep((cur) => cur + 1);
    }

    const handlePrev = () => {
        if (isFirstStep)
            return
        steps[activeStep].prevAction()    
        setError(null)
        setActiveStep((cur) => cur - 1)
        // resetState()
    }

    const studentAdded = (e) => {
        const value = e.target.value
        const derivedIds = selectedIds
        if(!e.target.checked && derivedIds.has(value)) {
            derivedIds.delete(value)
            setSelectedIds(derivedIds)
        } else {
            derivedIds.add(value)
            setSelectedIds(derivedIds)
        }
    }

    function Icon() {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clipRule="evenodd"
            />
          </svg>
        );
      }

    function termsCount(event, item) {
        const updated = new Set([...activeTermsCount])
        if (event.target.checked) {
            updated.add(item)
            setActiveTermsCount(updated);
        } else {
            updated.delete(item)
            setActiveTermsCount(updated)
        }
    }

    return <Dialog open={showEnrollModal} handler={closeModal} size="xl" popup dismiss={{enabled: false}} >
                <DialogHeader>
                    <div className="flex flex-between py-4 px-6 w-full">
                        
                        <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                            <div className="text-xs font-light text-gray-500 dark:text-white"> Enroll members &#40; Step {activeStep+1} of {steps.length}&#41; </div>
                            <div>{steps[activeStep].title} </div>
                        </div>
                        <button onClick={() => props.closeModal()} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>
                </DialogHeader>
                <DialogBody className="p-4 h-[32rem] bg-[#f6f7f8] overflow-scroll w-[95%] m-auto  p-6 relative">
                    {activeStep === 0 && memberDetails && <div className="h-full">
                                <div className="sm:mx-20 pt-5 pb-10 grid grid-cols-1 lg:grid-cols-4 gap-2">
                                    {memberDetails.filter(member => !member.programEnrollment)
                                        .map((member, i) => <InfoCard classNames={'!bg-white !shadow overflow-auto'} key={i} height={'h-60'} member={member} checkAction={studentAdded}/>)} 
                                </div>
                        </div>}
                    {activeStep === 1 && <div className="overflow-scroll">
                            <List>
                                <ListItem className="p-0">
                                        <label htmlFor={`term-00`} className="flex w-full cursor-pointer items-center px-3 py-2">
                                            <ListItemPrefix className="mr-3">
                                                <Checkbox color="blue" id={`term-00`} ripple={false}
                                                        className="hover:before:opacity-0"
                                                        containerProps={{className: "p-0", }} onChange={(event) => termsCount(event, `check_00`)} />
                                            </ListItemPrefix>
                                            <Typography color="blue-gray" className="font-medium  text-sm">
                                                I understand that a minimum of
                                                <select label="Select a billing schedule" className="mx-2 text-xs bg-white border-none" onChange={({ target: { value } }) => setContract(value)} >
                                                    {
                                                        [...Array(12).keys()].map(i =>
                                                            <option value={i + 1} selected={i + 1 === contract}>{i + 1}</option>)
                                                    }
                                                </select>
                                                monthly billings are required before canceling or freezing my account. NO EXCEPTIONS
                                            </Typography>
                                        </label>
                                </ListItem>
                                {conditions.map((condition, k) => 
                                    <ListItem key={k} className="p-0">
                                        <label htmlFor={`term-${k}`} className="flex w-full cursor-pointer items-center px-3 py-2">
                                            <ListItemPrefix className="mr-3">
                                                <Checkbox color="blue" id={`term-${k}`} ripple={false}
                                                        className="hover:before:opacity-0"
                                                        containerProps={{className: "p-0", }} onChange={(event) => termsCount(event, `check_${k}`)} />
                                            </ListItemPrefix>
                                            <Typography color="blue-gray" className="font-medium  text-sm">
                                                {condition}
                                            </Typography>
                                        </label>
                                    </ListItem>
                                )}
                                <ListItem>
                                    <label>
                                        <Typography color="blue-gray" className="font-medium text-sm">
                                            <div className="text-xl	font-bold my-2">Discalimer</div>
                                            <div>
                                                Tae Kwon Do is a physical activity that includes running, jumping, kicking, punching, and may not be suited to some existing physical ailments. By registering, you are stating that you are in good physical condition and understand the risks involved in any sport. You agree to follow the rules of our school and agree not to hold this school, its owner (Master Min), the landlord, or any of his employees liable for any injury incurred here.
                                            </div>
                                        </Typography>
                                    </label>
                                </ListItem>
                            </List>
                    </div>}  
                    {activeStep === 2 &&  <div className="md:mx-20  h-full">
                            <div className="flex flex-col justify-center items-center text-sm">
                                <PaymentMethods selectable={true} onSelect={(key) => setPaymentKey(key)}/>
                                <div className="flex flex-col justify-center overflow-scroll items-center relative">
                                    <ListItem className="p-0">
                                            <label htmlFor={`term-payment`} className="flex w-full cursor-pointer items-center px-3 py-2">
                                                <ListItemPrefix className="mr-3">
                                                    <Checkbox color="blue" id={`term-payment`} ripple={false}
                                                            className="hover:before:opacity-0"
                                                            containerProps={{className: "p-0", }} onChange={(event) => setPaymentTerms(event.target.checked)} />
                                                </ListItemPrefix>
                                                <Typography color="blue-gray" className="font-medium text-sm">
                                                    <div>By checking this box, you authorize automatic monthly billing for your selected subscription plan, <u><b>incurring an extra $5 charge for card transactions.</b></u></div>
                                                </Typography>
                                            </label>
                                    </ListItem>
                                </div>
                            </div>
                    </div>}
                    {activeStep === 3 && orderSummary && <EnrollmentSummary classNames={'text-sm'} summary={orderSummary} />}
                </DialogBody>
                <DialogFooter className="px-20 pb-6 justify-stretch	">
                    <Stepper activeStep={activeStep} isLastStep={(value) => setIsLastStep(value)} isFirstStep={(value) => setIsFirstStep(value)} >
                        <Step><HiMiniUserPlus /></Step>
                        <Step><TbChecklist /></Step>
                        <Step><MdOutlinePayment /></Step>
                        <Step><BsCartPlus /></Step>
                    </Stepper>
                    {error && <p className="text-xs text-red-600 mt-6 md:hidden">{error}</p>}
                    <div className="mt-6 flex justify-between w-full">
                        <Button onClick={handlePrev} disabled={isFirstStep}> Prev </Button>
                        {error && <p className="text-xs text-red-600 mt-2 hidden md:block">{error}</p>}
                        <Button onClick={handleNext}> {steps[activeStep].buttonValue} </Button>
                    </div>
                </DialogFooter>
    </Dialog>
}

export default BookClass