import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react"
import { useRef, useState } from "react"
import { BiCloudUpload } from 'react-icons/bi'
import { useDispatch } from "react-redux"
import { utilityActions } from "../../../_store"

const Uploader = (props) => {
    const fileRef = useRef()
    const dispatch = useDispatch()
    const [file, setFile] = useState(null)

    const handleChange = (event) => {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }

    const uploadFile = async () => {
        const files = fileRef.current.files;

        if(!file) return
        const body = new FormData();
        body.append('file', files[0])
        const resp = await dispatch(utilityActions.uploadFile(body))
        if (resp.meta.requestStatus === 'fulfilled') {
            props.action(null)
        }
    }
    
    return <Dialog open={true} handler={() => props.action(null)} size="md" popup dismiss={{ enabled: false }}>
            <DialogHeader>
                <div className="flex flex-between py-2 px-6 w-full">
                        <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                            <div>Upload Schedules </div>
                        </div>
                        <button onClick={() => props.action(null)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                </div>
            </DialogHeader>
            <DialogBody className=" h-full  overflow-scroll w-[98%] m-auto">
                <div className="flex flex-col justify-center items-center mx-5 py-10 border-dashed border-2 rounded bg-[#f6f7f8] hover:cursor-pointer text-sm" onClick={() => fileRef.current.click()}>
                    <input type="file" ref={fileRef} className="hidden" onChange={handleChange} accept="image/*" />
                    {file ? <div className="text-center">Selected: {file.name} <br/> or..</div>: <></>}
                    <BiCloudUpload className="h-20 w-24 m-0"/>
                    <div>Click to open</div>
                </div>
            </DialogBody>
            <DialogFooter className="pb-6 justify-end">
                <Button onClick={() => uploadFile()}>Upload</Button>
            </DialogFooter>
        </Dialog>
}

export default Uploader