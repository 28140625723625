import { Dialog, DialogBody, DialogFooter, DialogHeader, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Select, Option } from "@material-tailwind/react"
import GeneralInfo from "../../accounts/sections/GeneralInfo"
import MembersForm from "../../../components/members-form/MembersForm"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { membersActions, ordersActions, userActions } from "../../../_store"
import EnrollmentSummary from "../../../components/enrollment-summary/EnrollmentSummary"
import ManagePaymentSchedule from "../../../components/manage-payment-schedule/ManagePaymentSchedule"
import { safeParseDateAsIsoString } from "../../../_helpers"

const ManageMembers = (props) => {

    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(0);
    const [enrollmentId, setEnrollmentId] = useState(null)
    const { members: members } =  useSelector(state => state.members)
    const { enrollments: enrollments } =  useSelector(state => state.orders)
    const {guardianUser: userDetails} = useSelector(state => state.user)

    useEffect(() => {
        if(props.selectedUserId) {
            const payload = {
                user_id: props.selectedUserId
            }
            dispatch(membersActions.members(payload))
            dispatch(userActions.getUserById(payload))

            const schedulePayload = {
                action: "all",
                user_id: props.selectedUserId
            }
            dispatch(ordersActions.getEnrollments(schedulePayload))
        }
    }, [props.selectedUserId])

    const updateAction = () => {
        const payload = {
            user_id: props.selectedUserId
        }
        return dispatch(userActions.getUserById(payload))
    }

    const data = [{
        label: 'General',
        value: 0,
    }, {
        label: 'Billings & Schedule',
        value: 1
    }]

    const changing = (e) => {
        setEnrollmentId(e)
    }

    return <Dialog open={true} handler={() => props.action(null)} size="xxl" popup dismiss={{ enabled: false }}>
            <DialogHeader>
                <div className="flex flex-between py-2 px-6 w-full">
                        <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                            <div>Manage Members </div>
                        </div>
                        <button onClick={() => props.action(null)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                </div>
            </DialogHeader>
            <DialogBody className=" h-full overflow-scroll w-[98%] m-auto">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-96 m-auto"
                        indicatorProps={{
                        className:
                            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                        }}
                    >
                    {data.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => setActiveTab(value)}
                                style={{fontFamily: "Poppins, sans-serif"}}
                                className={activeTab === value ? "text-gray-900" : "text-gray-400"}
                            >
                                {label}
                            </Tab>
                            ))}

                    </TabsHeader>
                    <TabsBody>
                        <TabPanel key={0} value={0}>
                                <div className="bg-[#f6f7f8] px-8 lg:px-40 py-10">
                                    <MembersForm userId={props.selectedUserId} members={members} />
                                    <GeneralInfo userDetails={userDetails} updateAction={updateAction}/>
                                </div>
                        </TabPanel>
                        <TabPanel className="h-screen overflow-scroll" key={0} value={1}>
                                <div className="col-span-2 h-full bg-[#f6f7f8] sm:px-40 py-10">
                                    {
                                        enrollments && enrollments.length > 0 ?
                                            <div>
                                                <div className="w-[96%] lg:w-96 flex m-auto justify-center items-center gap-4">
                                                    <div className="font-bold">Schedules:</div>
                                                    <Select label="Select a billing schedule" className="bg-white" onChange={(value) => changing(value)}>
                                                        {enrollments.map((e, i) => <Option key={i} value={i}>Order - {safeParseDateAsIsoString(e.paymentDate)}</Option>)}
                                                    </Select>
                                                </div>
                                                <div>
                                                    {(enrollmentId === 0 || enrollmentId) && <ManagePaymentSchedule userId={props.selectedUserId} enrollment={enrollments[enrollmentId]} />}
                                                </div>
                                                <div className="">
                                                    {(enrollmentId === 0 || enrollmentId) &&  <EnrollmentSummary classNames={'!mx-0 !my-0 text-sm'} summary={JSON.parse(enrollments[enrollmentId].summary)} members={enrollments[enrollmentId].enrolled_members} />}
                                                </div>
                                            </div>
                                            :   
                                            <div className="text-sm h-full flex items-center justify-center">
                                                No enrollment found
                                            </div>
                                    }
                                </div>
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </DialogBody>
            <DialogFooter className="px-20 pb-6 justify-stretch	">
            </DialogFooter>
    </Dialog>
}

export default ManageMembers