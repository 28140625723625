
import { useEffect, useState } from "react";
import SectionCard from "../section-card/SectionCard"
import { FaUserGroup } from "react-icons/fa6"
import { useDispatch } from "react-redux";
import { membersActions, ordersActions, utilityActions } from "../../_store";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getPlanStatus, plans } from "../../_helpers";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import { Button, Checkbox } from "@material-tailwind/react";
import { FaPlay, FaPause, FaMinusCircle } from "react-icons/fa";
import DeleteAlert from "../alert-box/DeleteAlert";

const MembersForm = (props) => {
    
    const dispatch = useDispatch()

    const [members, setMembers] = useState([])
    const [userId, setUserId] = useState([])
    const [defaultEnrollmentStatus, setDefaultEnrollmentStatus] = useState({})
    const [selectedIds, setSelectedIds] = useState(new Set())
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        if(props.members) {
            const payload = props.members.map(member => {
                return { 
                        id: member.id,
                        name: member.name,
                        first_name: member && member.name ? member.name.split('-')[0] : null,
                        middle_initial: member && member.name ? member.name.split('-')[1] : null,
                        last_name: member && member.name ? member.name.split('-')[2] : null,
                        dob: member.dob ? new Date(member.dob) : member.dob,
                        plan: member.plan ? member.plan : null,
                        programEnrollment: member.programEnrollment ? member.programEnrollment : null,
                        enrollmentId: member.programEnrollment ? member.programEnrollment.id : null
                    }
            })
            const enrollmetPayload = {}
            props.members.forEach(member => {
                if(member.programEnrollment) {
                    enrollmetPayload[member.programEnrollment.id] = member.programEnrollment.enrollmentStatus
                }
            })
            setDefaultEnrollmentStatus(enrollmetPayload)
            setMembers(payload)
        }
    }, [props.members])

    useEffect(() => {
        if(props.userId) {
            setUserId(props.userId)
        }
    }, [props.userId])
    
    const defaultValue = {
        members: members.map(member => {
            return { 
                        id: member.id,
                        name: member.name,
                        first_name: member && member.name ? member.name.split('-')[0] : null,
                        middle_initial: member && member.name ? member.name.split('-')[1] : null,
                        last_name: member && member.name ? member.name.split('-')[2] : null,
                        dob: member.dob ? new Date(member.dob) : member.dob,
                        plan: member.plan ? member.plan.name : null,
                        programEnrollmentStatus: member.programEnrollment ? member.programEnrollment.enrollmentStatus : null,
                        enrollmentId: member.enrollmentId
                }
        })
    }

    useEffect(() => {
        reset(defaultValue)
    }, [members])
    
    const [editable, setEditable] = useState(false); 
    
    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('Name is required'),
        // dob: Yup.date().required('Require proper format'),
    });

    const formOptions = { resolver: yupResolver(validationSchema), defaultValues: defaultValue };


    const { register, handleSubmit, control, formState, reset } = useForm(formOptions);


    const { fields, remove, append } = useFieldArray({
        control,
        name: "members"
    });

    const { errors, isSubmitting } = formState;

    const onSubmit = async (data) => {
        console.log(data)
        const payload = data.members.map(d => {
            return {
                id: d.id,
                name: d.first_name + '-' + (d.middle_initial ?? '') + '-' + (d.last_name ?? ''),
                dob: d.dob
            }
        })

        const successPayload = {
            user_id: userId
        }
        
        const action = () => dispatch(membersActions.members(successPayload))

        console.log(payload)
        const resp = await dispatch(membersActions.postMembers({ payload, action }))
        setEditable(!editable)
    }

    const updateMembers = async (type) => {
        if(selectedIds.size === 0) {
            const errorPayload = {
                type: 'ERROR',
                message: "Select a members to update the status"
            }
            dispatch(utilityActions.toast(errorPayload))
            dispatch(utilityActions.showToast(true))
            return
        }

        const payload = {
            user_id: userId,
            action: "update"
        }

        if(type === 'pause') {
            payload['paused_enrollment_ids'] = [...selectedIds]
        } else if (type === 'resume') {
            payload['resumed_enrollment_ids'] = [...selectedIds]
        } else if (type === 'deactivate') {
            payload['deactivate_enrollment_ids'] = [...selectedIds]
        } else {
            return
        }
        
        const successPayload = {
            user_id: userId
        }
        const schedulePayload = {
            action: "all",
            user_id: userId
        }

        const action = async () => {
            await dispatch(membersActions.members(successPayload))
            await dispatch(ordersActions.getEnrollments(schedulePayload))
            if(type === 'resume') {
                const toastPayload = {
                    type: 'WARN',
                    message: "Kindly adjust the recurring billing date in the Billings & schedule."
                }
                await dispatch(utilityActions.toast(toastPayload))
                await dispatch(utilityActions.showToast(true))
            }
        }

        const resp = await dispatch(ordersActions.updateEnrollments({ payload, action }))
        console.log(payload)
    }

    const disabled_theme = 'border-none w-full bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg cursor-not-allowed'

    const enabled_theme = 'w-full bg-gray-50 bg-white border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg'

    const additionalActions = <>
                    <Button color="red" size="sm" onClick={() => setOpenDeleteModal(!openDeleteModal)} variant="outlined" className="mr-4 text-[10px]" >
                        <div className="flex items-center">
                            <FaMinusCircle/> <div className="hidden lg:block pl-1">Deactivate</div>
                        </div>
                    </Button>
                    <Button size="sm" onClick={() => updateMembers('pause')} variant="outlined" className="mr-4 text-[10px]">
                        <div className="flex items-center">
                            <FaPause/> <div className="hidden lg:block pl-1">Pause</div>
                        </div>
                    </Button>
                    <Button size="sm" onClick={() => updateMembers('resume')} variant="outlined" className="mr-4 text-[10px]" >
                        <div className="flex items-center">
                            <FaPlay/> <div className="hidden lg:block pl-1">Resume</div>
                        </div>
                    </Button>  
                </>

    const childProps = {
        editable: editable,
        setEditable: () =>  { 
            reset()
            setEditable(!editable)
        },
        saveAction: handleSubmit(onSubmit),
        headerIcon: <FaUserGroup/>,
        title: "Members",
        isSubmitting: isSubmitting,
        additionalActions: additionalActions
    }

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 1);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    const TABLE_HEAD = [
        {
            "title": "Select",
        }, {
            "title": "First name",
            "class": "col-span-2"
        }, {
            "title": "Last name",
            "class": "col-span-2"
        }, {
            "title": "Date of Birth",
            "class": "col-span-2"
        }, {
            "title": "Plan",
            "class": "col-span-2"
        }, {
            "title": "Status",
            "class": "col-span-2"
        }] 

    const checked = (member, e) => {
        if(!member.programEnrollment) return
        const updatedSelectedIds = selectedIds
        if(e.target.checked) {
            updatedSelectedIds.add(member.programEnrollment.id)
        } else {
            updatedSelectedIds.delete(member.programEnrollment.id)
        }
        setSelectedIds(updatedSelectedIds)
    }

    return <SectionCard {...childProps} showEditable={true}>
            <div className="py-4">
                <form >
                    <div className="grid grid-cols-11 gap-2 mb-4">
                        {TABLE_HEAD.map(header => <div className={`block text-sm text-gray-500 ${header.class}`}>{header.title}</div>)}
                    </div>
                    {members.map((member, index) => <div key={member.id} className="grid grid-cols-11 gap-2 mb-4">
                        <div>
                            <Checkbox onClick={(e) => checked(member, e)} color="blue" disabled={!member.programEnrollment}/>
                        </div>
                        <div className="col-span-2">
                            <input {...register(`members[${index}].first_name`)} disabled={!editable} className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="-" />
                        </div>
                        <div className="hidden">
                            <input {...register(`members[${index}].middle_initial`)} disabled={!editable} className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="-" />
                        </div>
                        <div className="col-span-2">
                            <input {...register(`members[${index}].last_name`)} disabled={!editable} className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="-" />
                        </div>
                        <div className="col-span-2">
                            <Controller
                                control={control}
                                name={`members[${index}].dob`}
                                render={({ field }) => (
                                <DatePicker
                                    disabled={!editable} className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                            </select>
                                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                            </select>
                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                        </div>
                                    )}
                                    showIcon
                                    icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText='MM/DD/YYYY'
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                />
                            )}
                            />
                        </div>
                        <div className="col-span-2">
                            <select {...register(`members[${index}].plan`)}  id="plan" disabled={true} className={`${disabled_theme}`} required >
                                {plans.map((p, i) => <>
                                    <option value={p.value}>{p.display}</option>
                                </>)}
                            </select>
                        </div>
                        <div className="col-span-2">
                            <input disabled={true} className={`${disabled_theme} !text-gray-500`} type="text" placeholder="Name" value={getPlanStatus(member.programEnrollment).display}/>
                        </div>
                        <div {...register(`members[${index}].id`)} className="hidden"/>
                        <div {...register(`members[${index}].enrollmentId`)} className="hidden"/>
                    </div>)}
                </form>
            </div>
            <DeleteAlert openModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
                                msg="Are you sure you want to deactivate this member"
                                confirmAction={() => updateMembers('deactivate')}/> 
        </SectionCard>
}

export default MembersForm