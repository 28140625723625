import { useDispatch, useSelector } from "react-redux"
import TopNav from "../../components/topnav/TopNav"
import GeneralInfo from "./sections/GeneralInfo"
import SecurityInfo from "./sections/SecurityInfo"
import { useEffect } from "react"
import { userActions } from "../../_store"


const Accounts = () => {

    const dispatch = useDispatch()
    const {user: userDetails} = useSelector(state => state.user)

    useEffect(() => {
        if(!userDetails.id)
            dispatch(userActions.getUser())
    }, [])

    return <div>
        <TopNav />
        <div className="w-[95%] m-auto mt-6">
            <GeneralInfo userDetails={userDetails} />
            <SecurityInfo />
        </div>
    </div>
}

export default Accounts