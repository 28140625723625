import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import { userActions } from './user.slice';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });


// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        resetPasswordSteps: 'VERIFY_PENDING',
        error: null
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        localStorage.removeItem('user');
        Object.assign(state, createInitialState())
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;

    return {
        login: login(),
        register: register(),
        generatecode: generatecode(),
        resetpassword: resetpassword(),
        cleanUp: cleanUp(),
        impersonate: impersonate()
    };    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }, { dispatch }) => await fetchWrapper.post(`${baseUrl}/signin?` + new URLSearchParams({ email: email, password: password}), null, null, dispatch)
        );
    }

    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async (data, { dispatch }) => {
                const loginPayload = {
                    email: data.email,
                    password: data.password
                }
                await fetchWrapper.post(`${baseUrl}/register`, data, () => dispatch(authActions.login(loginPayload)), dispatch)
            }
        );
    }

    function generatecode() {
        return createAsyncThunk(
            `${name}/generatecode`,
            async (data, { dispatch }) => {
                await fetchWrapper.post(`${baseUrl}/generate-code`, data, null, dispatch)
            }
         )
    }

    function resetpassword() {
        return createAsyncThunk(
            `${name}/resetpassword`,
            async(data,{ dispatch }) => await fetchWrapper.post(`${baseUrl}/reset-password`,data, null,dispatch)
        )
    }

    function cleanUp() {
        return createAsyncThunk(
            `${name}/cleanup`,
            async(data,{ dispatch }) => await fetchWrapper.delete(`${baseUrl}/cleanup`, data, () => dispatch(userActions.getAllUsers()), dispatch)
        )
    }

    function impersonate() {
        return createAsyncThunk(
            `${name}/impersonate`,
            async({ email }, { dispatch }) => await fetchWrapper.post(`${baseUrl}/impersonate?` + new URLSearchParams({ email: email }), null, null, dispatch)
        )
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...register(),
        ...generatecode(),
        ...resetpassword(),
        ...impersonate()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function register() {
        var { pending, fulfilled, rejected } = extraActions.register;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function generatecode() {
        var { pending, fulfilled, rejected } = extraActions.generatecode;
        return {
            [pending]: (state) => {
                console.log("pending",state);
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                console.log("user forgot", action);
                state.resetPasswordSteps = 'VERIFY_PASSWORD'
            },
            [rejected]: (state, action) => {
                // console.log("rejected",state);
                state.error = action.error;
            }
        };
    }

    function resetpassword() {
        var { pending, fulfilled, rejected } = extraActions.resetpassword;
        return {
            [pending]: (state) => {
                // console.log("pending",state);
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                console.log("reset", action);

                state.resetPasswordSteps = 'PASSWORD_RESET_SUCCESS'
            },
            [rejected]: (state, action) => {
                // console.log("rejected",state);
                state.error = action.error;
            }
        };
    }

    function impersonate() {
        var { pending, fulfilled, rejected } = extraActions.impersonate;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                localStorage.setItem('user', JSON.stringify(user));
                state.user = { ...state.user, user: user };
                window.location.reload();
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
