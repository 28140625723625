import { Checkbox, Badge, Button, Chip } from "@material-tailwind/react";
import { APPROVAL_MODAL, MANAGE_MEMBERS_MODAL, formatPhoneNumber, utilities } from "../../_helpers";
import { IoIosLogIn } from "react-icons/io";


const TableRow = ({ action, data, classes, checked, impersonate}) => {

        return <tr>
                    <td className={classes}>
                        <div className="flex flex-col">
                            <Checkbox onClick={(e) => checked(data.id, e)} color="blue"/>
                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                                <div variant="small" color="blue-gray" className="text-base" >
                                    <div>{utilities.capitalize(`${data.last_name} ${data.first_name}`)}</div>
                                    <div className="text-xs text-gray-500">Members : {data.member_count}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex flex-col ">
                            <div variant="small" color="blue-gray" className="font-normal opacity-70" >
                                {formatPhoneNumber(data.phoneno)}
                            </div>
                        </div>
                    </td>
                    <td className={classes}>
                        <div variant="small" color="blue-gray" className="font-normal" >
                            {`${data.email}`}
                        </div>
                    </td>
                    <td className={classes}>
                        <div variant="small" color="blue-gray" className="font-normal" >
                            P{`${data.planVersionId}`}
                        </div>
                    </td>
                    <td className={classes}>
                        <div variant="small" color="blue-gray" className="font-normal w-4/6 m-auto flex flex-col space-y-2" >
                            {data.pending_enrollment_ids.length > 0 && <Chip variant="ghost" color="indigo" size="sm" value={<div>{data.pending_enrollment_ids.length} Pending</div>}/>}
                            {data.paused_enrollment_ids.length > 0 && <Chip variant="ghost" color="amber" size="sm" value={<div>{data.paused_enrollment_ids.length} Paused</div>}/>}
                            {data.active_enrollment_ids.length > 0 && <Chip variant="ghost" color="green" size="sm" value={<div>{data.active_enrollment_ids.length} Active</div>}/>}
                            {(data.member_count === 0 || (data.pending_enrollment_ids.length + data.paused_enrollment_ids.length + data.active_enrollment_ids.length) !== data.member_count) && 
                                <Chip variant="ghost" size="sm" value={<div>{data.member_count - (data.pending_enrollment_ids.length + data.paused_enrollment_ids.length + data.active_enrollment_ids.length)} Draft</div>}/>}
                        </div>
                    </td>
                    <td className={`${classes}`}>
                        <div className="flex">
                            <Button size="sm" color="black" variant="outlined" 
                                onClick={() => action(MANAGE_MEMBERS_MODAL, data.id)}>
                                <span className="text-[10px]">Manage Family</span>
                            </Button>
                            <Badge content={"!"} invisible={data.pending_enrollment_ids.length === 0}
                                    className="bg-gradient-to-tr from-orange-400 to-orange-600 border-2 border-orange"
                                    >
                                <Button size="sm" variant="outlined" 
                                        disabled={data.pending_enrollment_ids.length === 0}
                                        onClick={() => action(APPROVAL_MODAL, data.id)}
                                        className="ml-4">
                                    <span className="text-[10px]">Approvals</span>
                                </Button>
                            </Badge>

                            <Button size="sm" color="black" variant="outlined" className="ml-1" onClick={() => impersonate(data.email)}>
                                <IoIosLogIn className="text-[20px]"  />
                            </Button>
                        </div>
                    </td>
            </tr>
}

export default TableRow