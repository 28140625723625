import { Button } from "@material-tailwind/react"
import { Spinner } from "flowbite-react"

const SectionCard = (props) => {
    return <div className={`overflow-x-scroll md:w-full bg-white shadow p-4 my-4 `}>
        <div className="overflow-x-scroll w-[650px] md:w-full">
            <div className={`grid h-12 ${props.showEditable ? 'grid-cols-2' : ''}`}>
                    <div className="flex items-center text-xl text-gray-800 font-bold">
                            {props.headerIcon}
                            <div className="pl-2">{props.title}</div>
                            {props.action ? <div className="flex ml-auto"> {props.action}</div> : <div/>}
                    </div>
                    <div>
                        <div className="flex justify-end">
                            {props.showEditable && (props.editable ?
                                <>
                                    <Button size="sm" onClick={() => props.setEditable()} variant="outlined" className="mr-4 text-[10px]">
                                        Cancel
                                    </Button>
                                    <Button size="sm" onClick={() => props.saveAction()} className="text-[10px]" >
                                        {props.isSubmitting ? <Spinner /> : 'Save'}
                                    </Button>  
                                </>:
                                <>
                                    <div className="border-r-2 mr-4">
                                        {props.additionalActions ? props.additionalActions : <></>}
                                    </div>
                                    <Button size="sm"
                                            onClick={() => props.setEditable()}>
                                            <span className="text-[10px]">Edit</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
            </div>
            <div className={`py-3 ${props.classNames ? props.classNames : ''}`}>
                {props.children}
            </div>
        </div>
    </div>
}

export default SectionCard