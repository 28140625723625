import { TextInput } from 'flowbite-react';
import Avatar from 'react-avatar';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const TopNav = (props) => {
    const { activePage: activePage } =  useSelector(state => state.utility)

    return <div className='h-[4.8rem] border-b bg-white'>
            <div className="w-[95%] m-auto grid grid-cols-2 h-full">
                <div className="flex items-center hover:cursor-pointer">
                    <h1 className="text-2xl text-gray-700 font-bold">{activePage}</h1>
                </div>
                {props.actions ?
                    <div className='flex justify-end items-center'>
                        {props.actions}
                        {/* <div className="pr-6">
                            <TextInput
                                className='w-72'
                                icon={AiOutlineSearch}
                                id="search-box"
                                placeholder="Search"
                                required
                            />
                        </div>
                        <Avatar size={44} fgColor='white' name={props.name} round={true} /> */}
                    </div>
                    :
                    <></>
                }
            </div>
            {props.children}
        </div>
}

export default TopNav