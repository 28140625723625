import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";


const ProtectedRoute = ({ roles = [], ...props }) => {
    
    const { user } =  useSelector(state => state.user)

    // user.roles && items.filter(item => item.allowed.some(a => user.roles.includes(a)))
    
    return user.roles && user.roles.some(element => roles.includes(element)) ? (
      props.element
    ) : (
      <Navigate to="/" />
    );
};

export default ProtectedRoute;