import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
// create slice

const name = 'members';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });


// exports

export const membersActions = { ...slice.actions, ...extraActions };
export const membersReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        members: [],
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/members`;

    return {
        members: members(),
        postMembers: postMembers()
    };    

    function members() {
        return createAsyncThunk(
            `${name}/members`,
            async (data, { dispatch }) => await fetchWrapper.get(baseUrl, data, null, dispatch)
        );
    }

    function postMembers() {
        return createAsyncThunk(
            `${name}/postMembers`,
            async ({ payload, action}, { dispatch }) => await fetchWrapper.post(baseUrl, payload, action, dispatch)
        );
    }
}

function createExtraReducers() {
    return {
        ...members()
    };

    function members() {
        var { pending, fulfilled, rejected } = extraActions.members;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const members = action.payload;
                state.members = members;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
