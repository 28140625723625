import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authHeader, fetchWrapper } from '../_helpers';

// create slice

const name = 'events';
const initialState = createInitialState();
const reducers = createReducers()
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const eventsActions = { ...slice.actions, ...extraActions };
export const eventsReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        events: [],
        showCreateEventsModal: false,
        error: null,
    }
}

function createReducers() {
    return {
        showCreateEventsModal
    }

    function showCreateEventsModal(state, action) {
        state.showCreateEventsModal = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/auth/events`;

    return {
        createEvent: createEvent(),
        getEvents: getEvents(),
        deleteEvent: deleteEvent()
    };    

    function createEvent() {
        return createAsyncThunk(
            `${name}/events/create`,
            async (data, { dispatch }) => {
                const options = {
                    method: 'POST',
                    body: data,
                    headers: {
                        ...authHeader()
                    }
                };
        
                return await fetchWrapper.custom(baseUrl, options, () => dispatch(eventsActions.getEvents()), dispatch)
            }
        );
    }

    function getEvents() {
        return createAsyncThunk(
            `${name}/events/fetch`,
            async (data, { dispatch }) => await fetchWrapper.get(baseUrl, data, null, dispatch)
        );
    }

    function deleteEvent() {
        return createAsyncThunk(
            `${name}/events/delete`,
            async (data, { dispatch }) => await fetchWrapper.delete(baseUrl, data, () => dispatch(eventsActions.getEvents()), dispatch)
        );
    }


}

function createExtraReducers() {
    return {
        ...getEvents()
    };

    function getEvents() {
        var { pending, fulfilled, rejected } = extraActions.getEvents;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.events = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
