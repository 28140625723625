import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import PaymentMethods from "./sections/PaymentMethods"
import PaymentSummary from "./sections/PaymentSummary"
import TopNav from "../../components/topnav/TopNav"

const Payments = () => {
    const dispatch = useDispatch()

    useEffect(() => {
    }, [])

    return <div>
        <TopNav />
        <div className="w-[85%] sm:w-[70%] m-auto mt-6">
            <div>
                <PaymentMethods/>
            </div>
            <div className="mt-12">
                <PaymentSummary/>
            </div>
        </div>
    </div>
}

export default Payments