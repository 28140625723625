import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react"
import { useState } from "react";
import { AiOutlineLock } from "react-icons/ai";
import { BsCalendarWeek } from "react-icons/bs";
import { BsFillCreditCardFill } from "react-icons/bs";
import Cheque from "../../img/cheque.svg"
import cc from "../../img/cc.svg"
import { Input, Button, Typography, Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { paymentActions } from "../../_store";

const PaymentModal = (props) => {

    const [type, setType] = useState("card");

    const cardFormInitialSate = {
        cardNumber: {value: "", error: ""},
        expires: {value: "", error: ""},
        cardHolder: {value: "", error: ""}
    }

    const checkFormInitialSate = {
        accountNumber: {value: "", error: ""},
        rountingNumber: {value: "", error: ""},
        holder: {value: "", error: ""}
    }

    const [ cardForm, setCardForm ] = useState(cardFormInitialSate)
    const [ checkForm, setCheckForm ] = useState(checkFormInitialSate)
    const dispatch = useDispatch()

    const updateCardForm = (event) => {
        const id = event.target.id
        const toUpdate = cardForm[id]
        toUpdate.value = event.target.value
        setCardForm({
            ...cardForm,
            [id]: toUpdate
        })
        setCardForm({
            ...cardForm,
            cardNumber: { ...cardForm.cardNumber, error: '' },
            expires: { ...cardForm.expires, error: '' },
            cardHolder: { ...cardForm.cardHolder, error: '' }
        })
    }

    const updateCheckForm = (event) => {
        console.log(event.target.id)
        console.log(event.target.value)
        const id = event.target.id
        const toUpdate = checkForm[id]
        toUpdate.value = event.target.value
        setCheckForm({
            ...checkForm,
            [id]: toUpdate
        })
        setCheckForm({
            ...checkForm,
            accountNumber: { ...checkForm.accountNumber, error: '' },
            rountingNumber: { ...checkForm.rountingNumber, error: '' },
            holder: { ...checkForm.holder, error: '' }
        })
    }
   
    const closeForm = () => {
        setCardForm(cardFormInitialSate)
        setCheckForm(checkFormInitialSate)
        props.setOpen(null)
    }

    const addCardPayment = async () => {
        if(cardForm.cardNumber.value == "") {
            setCardForm({
                ...cardForm,
                cardNumber: { ...cardForm.cardNumber, error: 'please enter the card number' }
            })
            return
        }
        if(cardForm.expires.value == "") {
            setCardForm({
                ...cardForm,
                expires: { ...cardForm.expires, error: 'please enter the expiry date' }
            })
            return
        }
        if(cardForm.cardHolder.value == "") {
            setCardForm({
                ...cardForm,
                cardHolder: { ...cardForm.cardHolder, error: 'please enter the name' }
            })
            return
        }
        const payload = {
            payment_mode: 'cc',
            cardholder: cardForm.cardHolder.value,
            cardno: cardForm.cardNumber.value.replace(new RegExp(" ", 'g'), ""),
            expiry: cardForm.expires.value.replace(new RegExp("/", 'g'), "")
        }
        const resp = await dispatch(paymentActions.addPaymentMethods(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            closeForm()
        }
    }

    const addCheckPayment =  async () => {
        console.log(checkForm)
        if(checkForm.accountNumber.value == "") {
            setCheckForm({
                ...checkForm,
                accountNumber: { ...checkForm.accountNumber, error: 'please enter the account number' }
            })
            return
        }
        if(checkForm.rountingNumber.value == "") {
            setCheckForm({
                ...checkForm,
                rountingNumber: { ...checkForm.rountingNumber, error: 'please enter the rounting number' }
            })
            return
        }
        if(checkForm.holder.value == "") {
            setCheckForm({
                ...checkForm,
                holder: { ...checkForm.holder, error: 'please enter the name' }
            })
            return
        }
        const payload = {
            payment_mode: 'check',
            routing: checkForm.rountingNumber.value,
            account: checkForm.accountNumber.value,
            account_type: 'checking',
            cardholder: checkForm.holder.value
        }
        console.log(payload)
        const resp = await dispatch(paymentActions.addPaymentMethods(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            closeForm()
        }
    }
     
    const formatCardNumber = (value) => {
        const val = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        const matches = val.match(/\d{4,16}/g);
        const match = (matches && matches[0]) || "";
        const parts = [];
   
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
   
        if (parts.length) {
            return parts.join(" ");
        } else {
            return value;
        }
  }
   
  const formatExpires = (value) => {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/^([2-9])$/g, "0$1")
      .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
      .replace(/^0{1,}/g, "0")
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
  }
   
  
 
    const paymentOptions = [
        {
            "title" : "Card Payment",
            "icon": <img src={cc} className="w-[2.5rem] pr-2 opacity-70" />,
            "paymentType": "card"
        }, {
            "title" : "Check",
            "icon": <img src={Cheque} className="w-[2.5rem] pr-2 opacity-70" />,
            "paymentType": "check"
        }
    ]

    return <Dialog open={props.open} size={"sm"}>
            <DialogHeader>
                Add Payment Method
            </DialogHeader>
            <DialogBody>
                <Tabs value={type} className="overflow-visible">
                    <TabsHeader className="relative z-0 ">
                        {paymentOptions.map(option =>
                        <Tab value={option.paymentType} onClick={() => setType(option.paymentType)}>
                            <div className="flex text-sm justify-center items-center">
                                {option.icon}
                                <div>{option.title}</div>
                            </div>
                        </Tab>
                        )}
                    </TabsHeader>
                    <TabsBody
                        className="!overflow-x-hidden !overflow-y-visible"
                            animate={{
                                initial: {
                                    x: type === "card" ? 400 : -400,
                                },
                                mount: {
                                    x: 0,
                                },
                                unmount: {
                                    x: type === "card" ? 400 : -400,
                                },
                            }}>
                        <TabPanel value="card" className="p-0">
                            <form className="flex flex-col gap-4">
                                <div className="my-6">
                                    <Typography variant="small" color="blue-gray" className="mb-4 font-medium" >
                                        Card Details
                                    </Typography>
                                    <Input label="Card Number" id="cardNumber" maxLength={19} value={formatCardNumber(cardForm.cardNumber.value)}
                                            onChange={(event) => updateCardForm(event)}
                                            icon={ <BsFillCreditCardFill className="h-5 w-5 text-blue-gray-300" /> } required="" />

                                            <p className="text-red-600 mt-2 text-xs">{cardForm.cardNumber.error}</p>
                                            
                                    <div className="my-4 grid grid-cols-2 gap-4">
                                        <Input  label="Expires" maxLength={5} id="expires" value={formatExpires(cardForm.expires.value)} 
                                                onChange={(event) => updateCardForm(event)}
                                                containerProps={{ className: "min-w-[72px]" }}
                                                icon={ <BsCalendarWeek className="h-5 w-4 text-blue-gray-300" /> }/>
                                        <div/>
                                        {/* <Input label="CVC" maxLength={4} containerProps={{ className: "min-w-[72px]" }} /> */}
                                        <p className="text-red-600 text-xs">{cardForm.expires.error}</p>
                                    </div>
                                    <Input id="cardHolder" value={cardForm.cardHolder.value} 
                                                onChange={(event) => updateCardForm(event)} label="Holder Name" />
                                                <p className="text-red-600 mt-2 text-xs">{cardForm.cardHolder.error}</p>
                                </div>
                                <div className="grid grid-cols-3 gap-1">
                                    <Button variant="outlined" onClick={() => closeForm()} >
                                        Cancel
                                    </Button>
                                    <Button className="col-span-2" onClick={() => addCardPayment()} size="lg">Add Payment</Button>
                                </div>
                                <Typography variant="small" color="gray" className="mt-2 flex items-center justify-center gap-2 font-normal opacity-60" >
                                    <AiOutlineLock className="-mt-0.5 h-4 w-4" /> Payment methods are securely stored
                                </Typography>
                            </form>
                        </TabPanel>
                        <TabPanel value="check" className="p-0">
                            <form className="flex flex-col gap-4">
                                <div className="my-6">
                                    <Typography variant="small" color="blue-gray" className="mb-4 font-medium" >
                                        Check Details
                                    </Typography>
                                    <Input label="Account Number" maxLength={17} id="accountNumber" value={checkForm.accountNumber.value}
                                            onChange={(event) => updateCheckForm(event)}
                                            icon={ <img src={Cheque} className="w-[3rem]" /> }/>
                                             <p className="text-red-600 mt-2 text-xs">{checkForm.accountNumber.error}</p>
                                    <div className="my-4 flex flex-col gap-4">
                                    <Input label="Routing Number" maxLength={9} id="rountingNumber" value={checkForm.rountingNumber.value}
                                            onChange={(event) => updateCheckForm(event)}/>
                                            <p className="text-red-600 text-xs">{checkForm.rountingNumber.error}</p>
                                    </div>
                                    <Input id="holder" value={checkForm.holder.value} 
                                                onChange={(event) => updateCheckForm(event)} label="Name" />
                                                 <p className="text-red-600 mt-2 text-xs">{checkForm.holder.error}</p>
                                </div>
                                <div className="grid grid-cols-3 gap-1">
                                    <Button variant="outlined" onClick={() => closeForm()} >
                                        Cancel
                                    </Button>
                                    <Button className="col-span-2" onClick={() => addCheckPayment()}  size="lg">Add Payment</Button>
                                </div>
                                <Typography variant="small" color="gray" className="mt-2 flex items-center justify-center gap-2 font-normal opacity-60" >
                                    <AiOutlineLock className="-mt-0.5 h-4 w-4" /> Payment methods are securely stored
                                </Typography>
                            </form>
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </DialogBody>
        </Dialog>
}

export default PaymentModal