import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Signin from './pages/signin/Signin';
import Signup from './pages/signup/Signup';
import Layout from './pages/layout/Layout';
import Home from './pages/home/Home';
import Members from './pages/members/Members';
import Accounts from './pages/accounts/Accounts';
import { useSelector } from 'react-redux';
import Schedule from './pages/schedule/Schedule';
import Resetpassword from './pages/resetpassword/Resetpassword';
import Payments from './pages/payments/Payments';
import Families from './pages/families/Families';
import Settings from './pages/settings/Settings';
import SnackBar from './components/snack-bar/SnackBar';
import ProtectedRoute from './ProtectedRoute';
import Billings from './pages/billings/Billings';
import SubscriptionManager from './pages/subscription-manager/SubscriptionManager';
import Events from './pages/events/Events';

const App = () => {

	const { user: authUser } = useSelector(state => state.auth);
	const { toast: toast} = useSelector(state => state.utility);
	const { showToast: showToast} = useSelector(state => state.utility);

	return (
		<BrowserRouter >
			<div className='default-font h-screen' id='top'>
				<SnackBar toast={toast} showToast={showToast} />
				{authUser ?
					<Layout>
						<Routes>
							<Route path="/accounts" 
									element={<ProtectedRoute roles={["MEMBER"]} element={<Accounts />}/>} />
							<Route path="/payments" 
								element={<ProtectedRoute roles={["MEMBER"]} element={<Payments />} />} />
							<Route path='/users' 
								element={<ProtectedRoute roles={["ADMIN"]} element={<Families />} />} />
							<Route path='/events'
								element={<ProtectedRoute roles={["ADMIN"]} element={<Events />} />} />
							<Route path='/subscription' 
								element={<ProtectedRoute roles={["ADMIN"]} element={<SubscriptionManager />} />} />
							<Route path="/members" 
								element={<ProtectedRoute roles={["MEMBER", "ADMIN"]} element={<Members />}/>}/>
							<Route path="/schedule" 
								element={<ProtectedRoute roles={["MEMBER", "ADMIN"]} element={<Schedule />}/>}/>
							<Route path="/billings" 
								element={<ProtectedRoute roles={["MEMBER"]} element={<Billings />}/>}/>	
							<Route path="/settings" 
								element={<ProtectedRoute roles={["ADMIN"]} element={<Settings />}/>}/>
							<Route path="/*" element={<Home />}/>
						</Routes>
					</Layout>
				:
					<Routes>
						<Route path="/signup" element={<Signup />}/>
						<Route path="/resetpassword" element={<Resetpassword />}/>
						<Route path="/*" element={<Signin />}/>
					</Routes>
				}
			</div>
		</BrowserRouter>
	);
}

export default App;
