import { useDispatch, useSelector } from "react-redux"
import { ordersActions } from "../../_store"
import TopNav from "../../components/topnav/TopNav"
import { useEffect, useState } from "react"
import EnrollmentSummary from "../../components/enrollment-summary/EnrollmentSummary"
import { Select, Option } from "@material-tailwind/react"
import NoData from "../../img/bg/no_data.svg"

const Billings = () => {

    const dispatch = useDispatch()

    const { user: user } = useSelector(state => state.user);
    const { enrollments: enrollments } =  useSelector(state => state.orders)
    const [enrollmentId, setEnrollmentId] = useState(null)

    useEffect(() => {
        const schedulePayload = {
            action: "all",
            user_id: user.id
        }
        console.log(schedulePayload)
        
        dispatch(ordersActions.getEnrollments(schedulePayload))
    }, [])

    return <div>
        <TopNav/>
        <div className="w-[95%] m-auto mt-6 flex justify-center h-full">
            {
                enrollments && enrollments.length > 0 ?
                    <div className="w-[75%]">
                        <div className="w-[95%] lg:w-96 flex m-auto justify-center items-center gap-4 mb-10">
                            <div className="font-bold">Billings:</div>
                            <Select label="Select a billing" className="bg-white" onChange={(value) => setEnrollmentId(value)}>
                                {enrollments.map((e, i) => <Option key={i} value={i}>Order - {new Date(e.paymentDate).toISOString().split("T")[0]}</Option>)}
                            </Select>
                        </div>
                        <div className="">
                            {(enrollmentId === 0 || enrollmentId) &&  <EnrollmentSummary classNames={'text-sm'} summary={JSON.parse(enrollments[enrollmentId].summary)} />}
                        </div>
                    </div>
                    :   
                    <div className="text-sm h-full flex flex-col gap-4 items-center justify-center">
                        <img className="w-36" src={NoData} />
                        <div>No Orders found</div>
                    </div>
            }
        </div>
    </div>
}

export default Billings