import { useOtpInput } from "react-otp-input-hook";

const OtpBox = ({ onChange }) => {
  const { register, inputs } = useOtpInput({
    placeholder: "-",
    numberOfInputs: 4,
    onInputValueChange: onChange,
  });

  const defaultOptions = { required: true };

  return (
    <div  className="flex gap-12">
        {inputs.map((input, i) => {
            return <input {...register(`digit-${i}`, defaultOptions)} className="text-center bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required key={i} {...input} />;
        })}
    </div>
  );
};

export default OtpBox