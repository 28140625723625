import { useDispatch, useSelector } from "react-redux"
import TopNav from "../../components/topnav/TopNav"
import { useEffect, useState } from "react"
import { authActions, userActions, utilityActions } from "../../_store"
import { Badge, Checkbox, Button, Card, CardBody, CardFooter, CardHeader, IconButton, Input, Typography, Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import TableRow from "../../components/table-row/TableRow";
import Approvals from "./sections/Approvals";
import { APPROVAL_MODAL, MANAGE_MEMBERS_MODAL } from "../../_helpers";
import ManageMembers from "./sections/ManageMembers";
import { BiRefresh } from "react-icons/bi"
import DeleteAlert from "../../components/alert-box/DeleteAlert";
import { FaMinusCircle } from "react-icons/fa";
import { MdOutlineFilterList } from "react-icons/md";


const Families = () => {

    const DEFAULT_STATE = { modal: null, id: null }
    const dispatch = useDispatch()
    const { users: users} = useSelector(state => state.user)
    const [selectedModal, setSelectedModal] = useState(DEFAULT_STATE)
    const [selectedUserIds, setSelectedUserIds] = useState(new Set())
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [tableRow, setTableRow] = useState([])
    const [filter, setFilter] = useState(new Set())
    const [pagination, setPagination] = useState({page: 1, totalPages: 1, totalCount: 0})

    const [search, setSearch]  = useState('')

    useEffect(() => {
        dispatch(userActions.getAllUsers())
    }, [])

    useEffect(() => {
        loadData(users.data)
        setSearch('')
        setFilter(new Set())
    }, [users])

    useEffect(() => {
        let data = users.data
        data = filterBySearch(data)
        data = filterByStatus(data)
        loadData(data)
    }, [search, filter])

    const loadData = (data) => {
        setTableRow(data)
        updatePagination(data)
        setSelectedUserIds(new Set())
    }

    const updatePagination = (data) => {
        const totalPages = parseInt(data.length / 10) + 1

        setPagination({
            ...pagination,
            page: 1,
            totalPages: totalPages,
            totalCount: data.length
        })
    }

    const onNext = () => {
        setPagination({
            ...pagination,
            page: pagination.page + 1
        })
    }

    const onPrevious = () => {
        setPagination({
            ...pagination,
            page: pagination.page - 1
        })
    }

    const paginate = (data) => {
        const start = (pagination.page - 1) * 10
        const end = start + 10
        return data.slice(start, end)
    }

    const action = (modal, userId) => {
        if(modal && userId) {
            setSelectedModal({
                ...selectedModal,
                id: userId,
                modal: modal
            })
        } else {
            setSelectedModal(DEFAULT_STATE)
        }
    }
    
    const refresh = () => {
        dispatch(userActions.getAllUsers())
    }

    const filterBySearch = (data) => {
        if(search.trim() === '') {
            return data
        }
        const out = data.filter(d =>  (d['last_name'] + " " + d['first_name']).toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        return out
    }

    const filterByStatus = (data) => {
        if(filter.size === 0) {
            return data
        }

        return data.filter(d => {
            if(filter.has('Pending') && d.pending_enrollment_ids.length > 0) return true
            if(filter.has('Paused') && d.paused_enrollment_ids.length > 0) return true
            if(filter.has('Active') && d.active_enrollment_ids.length > 0) return true
            if(filter.has('Draft') && (d.pending_enrollment_ids.length + d.paused_enrollment_ids.length + d.active_enrollment_ids.length) !== d.member_count) return true
            return false
        })
    }

    const deleteUsers = async () => {
        if(selectedUserIds.size === 0) {
            const errorPayload = {
                type: 'ERROR',
                message: "No user has been selected"
            }
            dispatch(utilityActions.toast(errorPayload))
            dispatch(utilityActions.showToast(true))
            return
        }
        const payload = {
            user_ids: [ ...selectedUserIds ]
        }
        const resp = await dispatch(authActions.cleanUp(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            setSelectedUserIds(new Set())
        }
    }

    const checked = (userId, e) => {
        const updatedSelectedUserIds = new Set(selectedUserIds)
        if(e.target.checked) {
            updatedSelectedUserIds.add(userId)
        } else {
            updatedSelectedUserIds.delete(userId)
        }
        setSelectedUserIds(updatedSelectedUserIds)
    }

    const filterOption = (option, e) => {
        console.log(option, " : ", e.target.checked)
        const updatedFilter = new Set(filter)
        if(e.target.checked) {
            updatedFilter.add(option)
        } else {
            updatedFilter.delete(option)
        }
        setFilter(updatedFilter)
    }

    const impersonate = (email) => {
        console.log(email)
        return dispatch(authActions.impersonate({ email }));
    }

    const TABLE_HEAD = ["Select", "Guardian", "Phone Number", "Email", "Plan",  "Members Status", "Action"];
    return <div>
            <TopNav />
            <div className="w-[95%] m-auto mt-6">
                <Card>
                    <CardHeader floated={false} shadow={false} className="rounded-xl m-0 mx-6 flex items-center justify-between py-6">
                        <div className="text-xl flex gap-4">
                            <div className="flex items-center w-full md:w-72">
                                <Input
                                label="Search Parent"
                                icon={<HiMagnifyingGlass className="h-5 w-5" />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                />
                            </div>
                            <div className="flex items-center">
                                <Menu
                                    dismiss={{
                                        itemPress: false,
                                    }}
                                    >
                                    <MenuHandler>
                                        <Button  variant="outlined" size="sm" color="blue-gray">
                                            <div className="flex items-center gap-1">
                                                <MdOutlineFilterList />
                                                {filter.size > 0 ? <div className="px-1 rounded-xl text-white bg-gray-500">{filter.size}</div> : <></>}
                                                <div>FILTER</div>
                                            </div>
                                        </Button>
                                    </MenuHandler>
                                    <MenuList>
                                        <MenuItem className="p-0">
                                            <label className="flex cursor-pointer items-center gap-2 p-2">
                                                <Checkbox checked={filter.has("Draft")} onClick={(e) => filterOption("Draft", e)} ripple={false} id="item-1" containerProps={{ className: "p-0" }} className="hover:before:content-none"/>
                                                Draft
                                            </label>
                                        </MenuItem>
                                        <MenuItem className="p-0">
                                            <label className="flex cursor-pointer items-center gap-2 p-2">
                                                <Checkbox checked={filter.has("Pending")} onClick={(e) => filterOption("Pending", e)} ripple={false} id="item-1" containerProps={{ className: "p-0" }} className="hover:before:content-none"/>
                                                Pending
                                            </label>
                                        </MenuItem>
                                        <MenuItem className="p-0">
                                            <label className="flex cursor-pointer items-center gap-2 p-2">
                                                <Checkbox checked={filter.has("Active")} onClick={(e) => filterOption("Active", e)} ripple={false} id="item-1" containerProps={{ className: "p-0" }} className="hover:before:content-none"/>
                                                Active
                                            </label>
                                        </MenuItem>
                                        <MenuItem className="p-0">
                                            <label className="flex cursor-pointer items-center gap-2 p-2">
                                                <Checkbox checked={filter.has("Paused")} onClick={(e) => filterOption("Paused", e)} ripple={false} id="item-1" containerProps={{ className: "p-0" }} className="hover:before:content-none"/>
                                                Paused
                                            </label>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Button color="red" size="sm" onClick={() => setOpenDeleteModal(!openDeleteModal)} variant="outlined" className="mr-4 text-[10px]" >
                                <div className="flex items-center">
                                    <FaMinusCircle/> <div className="pl-1">Delete</div>
                                </div>
                            </Button>
                            <IconButton size="sm" variant="outlined" onClick={() => refresh()}>
                                <BiRefresh className="text-xl"/>
                            </IconButton>
                        </div>
                    </CardHeader>
                    <CardBody className="p-0 overflow-x-scroll">
                        <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                    {TABLE_HEAD.map((head, index) => (
                                        <th key={head} 
                                            className={`cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50
                                                ${index === TABLE_HEAD.length - 1 && 'w-[20rem]'}`}
                                            >
                                        <div className="flex items-center justify-between gap-2 font-normal leading-none opacity-70" >
                                            {head}{" "}
                                        </div>
                                        </th>
                                    ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginate(tableRow).map(
                                        (row, index) => {
                                            const isLast = index === users.length - 1;
                                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                            return <TableRow key={index} data={row} classes={classes} checked={(userId, e) => checked(userId, e)} action={(modal, userId) => action(modal, userId)} impersonate={(email) => impersonate(email)}/>
                                        })}
                                    {paginate(tableRow).length === 0 && <tr> 
                                            <td colspan={TABLE_HEAD.length} className="py-20 text-center">
                                                    No data available
                                            </td>
                                    </tr>}
                                </tbody>
                        </table>
                    </CardBody> 
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal"> Page {pagination.page} of {pagination.totalPages} </Typography>
                        <div className="flex gap-2">
                            <Button variant="outlined" size="sm" disabled={pagination.page === 1} onClick={() => onPrevious()}>
                                Previous
                            </Button>
                            <Button variant="outlined" size="sm" disabled={pagination.page === pagination.totalPages}  onClick={() => onNext()}>
                                Next
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </div>
            {selectedModal.modal === APPROVAL_MODAL && <Approvals selectedUserId={selectedModal.id} action={(modal, userId) => action(modal, userId)} />}
            {selectedModal.modal === MANAGE_MEMBERS_MODAL && <ManageMembers selectedUserId={selectedModal.id} action={(modal, userId) => action(modal, userId)} />}
            <DeleteAlert openModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
                                msg="Are you sure you want to delete the users ?"
                                confirmAction={() => deleteUsers()}/>
        </div>
}

export default Families