import { useDispatch, useSelector } from "react-redux"
import BookClass from "../../book-class/BookClass"
import { ordersActions } from "../../../_store"

const Enroll = () => {
    const dispatch = useDispatch()
    const { showEnrollModal: showEnrollModal } =  useSelector(state => state.orders)

    const closeModal = () => {
        dispatch(ordersActions.showEnrollModal(false))
    }

    return <>
        {showEnrollModal && <BookClass closeModal={() => closeModal()}/>}
    </>
}

export default Enroll