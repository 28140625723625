import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { eventsActions } from "../../../_store"
import { Card, CardHeader, CardBody, Typography, Button, CardFooter, Dialog, DialogHeader, DialogBody, } from "@material-tailwind/react";
import { MdDateRange, MdDeleteForever } from 'react-icons/md'
import { formatDate } from "../../../_helpers";
import { FaDeleteLeft } from "react-icons/fa6";
import DeleteAlert from "../../../components/alert-box/DeleteAlert";


const EventsViews = (props) => {
    
    const dispatch = useDispatch()

    const eventsState =  useSelector(state => state.events)
    const docViewrDefaultState = {
        show: false,
        docUrl: null
    }

    const [ selectedEventId, setSelectedEventId ] = useState(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [docViewer, setDocViewer] = useState(docViewrDefaultState);
    

    useEffect(() => {
        dispatch(eventsActions.getEvents())
    }, [])

    const initDeleteAction = (id) => {
        setSelectedEventId(id)
        setOpenDeleteModal(true)
    }

    const deleteEvent = async () => {
        if(selectedEventId) {
            const body = {
                id: selectedEventId
            }
            const resp = await dispatch(eventsActions.deleteEvent(body))
            if (resp.meta.requestStatus === 'fulfilled') {
                setSelectedEventId(null)
            }
        }
    }

    const toogleDocViewer = (modalState, docUrl) => {
        setDocViewer({
            ...docViewer,
            show: modalState,
            docUrl: docUrl
        })
    }


    return <div>
        {
            eventsState.events && eventsState.events.length === 0 && 
                    <Card className="w-full flex-row">
                                <CardBody className="w-full text-center m-20">
                                    No Events available
                                </CardBody>
                    </Card>
        }

        {eventsState.events && eventsState.events.map((e, i) => {
            return <div className="flex my-6">
                    <Card key={i} className="w-full flex-row">
                        <CardBody className="w-full">
                            <div className="w-full grid grid-cols-10 gap-1">
                                    <div className="col-span-8">
                                        <div className="text-xl lg:text-2xl font-bold">{e.eventName}</div>
                                        <div className="py-6">{e.eventDesc}</div>
                                        {
                                            e.eventType !== 'INFORMATION' &&
                                                <div>
                                                    <div className="font-bold flex items-center align-center gap-2">
                                                        <div><MdDateRange /> </div>
                                                        <div>{formatDate(new Date(e.fromDate))}</div>
                                                        {e.toDate && <div>-</div>}
                                                        {e.toDate && <div>{formatDate(new Date(e.toDate))}</div>}
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="col-span-2  flex items-center align-center gap-2">
                                        <Button onClick={() => initDeleteAction(e.id)}  variant="text" color="red" className="flex items-right gap-2 !absolute top-4 right-6">
                                            <MdDeleteForever/>
                                        </Button>
                                        {
                                            e.asset && <Button variant="text" className="flex items-center gap-2" onClick={() => toogleDocViewer(true, e.asset.filePath)}>
                                                <div className="border-b-2 border-black"> Learn More </div>
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} className="h-4 w-4" >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                                />
                                                </svg>
                                            </Button>
                                        }
                                    </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
        })}
        <DeleteAlert openModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
                                msg="Are you sure you want to delete event ?"
                                confirmAction={() => deleteEvent()}/>
        <Dialog open={docViewer.show} handler={() => toogleDocViewer(false, null)} size="lg" popup dismiss={{ enabled: false }}>
                <DialogHeader>
                    <div className="flex flex-between py-4 px-6 w-full">
                            {/* <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                                <div> Event Infomration ! </div>
                            </div> */}
                            <button onClick={() => toogleDocViewer(false, null)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                    </div>
                </DialogHeader>
                <DialogBody className="h-[56rem] overflow-scroll w-[95%] m-auto">
                    <img className='lg:w-full' src={docViewer.docUrl} />
                </DialogBody>
        </Dialog>
    </div>
}

export default EventsViews