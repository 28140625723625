import { Button, Typography, Chip, Card, CardBody, CardFooter, CardHeader, Input } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { formatPhoneNumber, getPlanStatus } from "../../../_helpers";


const MemberTableView = (props) => {

    const [members, setMembers] = useState([])
    const [tableRow, setTableRow] = useState([])
    const [pagination, setPagination] = useState({page: 1, totalPages: 1, totalCount: 0})
    const [search, setSearch]  = useState('')
    useEffect(() => {
        if(props.memberDetails) {
            setMembers(props.memberDetails)
            loadData(props.memberDetails)
            setSearch('')
        }
    }, [props.memberDetails])

    useEffect(() => {
        let data = members
        data = filterBySearch(data)
        loadData(data)
    }, [search])

    const loadData = (data) => {
        setTableRow(data)
        updatePagination(data)
    }

    const updatePagination = (data) => {
        const totalPages = parseInt(data.length / 10) + 1

        setPagination({
            ...pagination,
            page: 1,
            totalPages: totalPages,
            totalCount: data.length
        })
    }


    const paginate = (data) => {
        const start = (pagination.page - 1) * 10
        const end = start + 10
        return data.slice(start, end)
    }

    const onNext = () => {
        setPagination({
            ...pagination,
            page: pagination.page + 1
        })
    }

    const onPrevious = () => {
        setPagination({
            ...pagination,
            page: pagination.page - 1
        })
    }
    const filterBySearch = (data) => {
        if(search.trim() === '') {
            return data
        }
        
        const out = data.filter(d => (d.name.split('-')[0] + " " + d.name.split('-')[2]).toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        return out
    }

    const TABLE_HEAD = ["", "Student Name", "Members Status", "Guardian", "Guardian Contact", "Action"];
    return <div>
            <Card>
                    <CardHeader floated={false} shadow={false} className="rounded-xl m-0 mx-6 flex items-center justify-between py-6">
                        <div className="text-xl flex gap-4">
                            <div className="flex items-center w-full md:w-72">
                                <Input
                                    label="Search Student"
                                    icon={<HiMagnifyingGlass className="h-5 w-5" />}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end font-bold">
                                Total Students: {tableRow.length}
                        </div>
                    </CardHeader>
                    <CardBody className="p-0 overflow-x-scroll">
                        <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                    {TABLE_HEAD.map((head, index) => (
                                        <th key={head} 
                                            className={`cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50`}
                                            >
                                            <div className="flex items-center justify-between gap-2 font-normal leading-none opacity-70" >
                                                {head}{" "}
                                            </div>
                                        </th>
                                    ))}
                                    </tr>
                                </thead>
                                
                               <tbody>
                                    {paginate(tableRow).map(
                                        (row, index) => {
                                            const isLast = index === members.length - 1;
                                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                            return <tr>
                                                <td className={`${classes}`}></td>
                                                <td className={`${classes} text-sm`}>{row.name.split('-')[0]} {row.name.split('-')[2]}</td>
                                                <td className={`${classes}`}>
                                                    <div className="w-20 text-center">
                                                        {getPlanStatus(row.programEnrollment).value  === 'PENDING_APPROVAL' && <Chip variant="ghost" color="indigo" size="sm" value={<div>Pending</div>}/>}
                                                        {getPlanStatus(row.programEnrollment).value  === 'PAUSED' && <Chip variant="ghost" color="amber" size="sm" value={<div>Paused</div>}/>}
                                                        {getPlanStatus(row.programEnrollment).value  === 'ACTIVE' && <Chip variant="ghost" color="green" size="sm" value={<div>Active</div>}/>}
                                                        {getPlanStatus(row.programEnrollment).value  === 'DRAFT' && <Chip variant="ghost" size="sm" value={<div>Draft</div>}/>}
                                                    </div>
                                                </td>
                                                <td className={`${classes}`}>{row.user.first_name} {row.user.last_name}</td>
                                                <td className={classes}>
                                                    <div>
                                                        <div>{row.user.email}</div>
                                                        <div className="text-xs text-gray-500">{formatPhoneNumber(row.user.phoneno)}</div>
                                                    </div>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Button size="sm" color="black" variant="outlined" 
                                                        onClick={() => props.editAction(row.id)}>
                                                        <span className="text-[10px]">Edit Student</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                            // return <TableRow key={index} data={row} classes={classes} checked={(userId, e) => checked(userId, e)} action={(modal, userId) => action(modal, userId)}/>
                                        })}
                                    {paginate(tableRow).length === 0 && <tr> 
                                            <td colspan={TABLE_HEAD.length} className="py-20 text-center">
                                                    No data available
                                            </td>
                                    </tr>}
                                </tbody>
                        </table>
                    </CardBody> 
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal"> Page {pagination.page} of {pagination.totalPages} </Typography>
                        <div className="flex gap-2">
                            <Button variant="outlined" size="sm" disabled={pagination.page === 1} onClick={() => onPrevious()}>
                                Previous
                            </Button>
                            <Button variant="outlined" size="sm" disabled={pagination.page === pagination.totalPages}  onClick={() => onNext()}>
                                Next
                            </Button>
                        </div>
                    </CardFooter>
                </Card> 
    </div>
}

export default MemberTableView