import { Spinner } from "flowbite-react"

const Loader = () => {
    return <div className="absolute w-full h-full z-[10000] flex justify-center align-center items-center" style={{background: '#dbdbdbcc'}}>
        <div>
            <Spinner aria-label="Info spinner example" color="gray" size="xl" />
        </div>
    </div>
}

export default Loader