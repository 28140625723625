import { store, authActions, utilityActions } from '../_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    custom: custom()
};

function client(END_POINT, requestOptions, dispatchAction, dispatch) {
    return fetch(END_POINT, requestOptions)
                    .then(response => handleResponse(response, dispatchAction))
                    .then(response => {
                        if(dispatchAction) {
                            dispatchAction(response)
                        } else {
                            dispatch(utilityActions.loading(false))
                        }
                        dispatch(utilityActions.showToast(false))
                        return response
                    })
                    .catch(e => {
                        dispatch(utilityActions.loading(false))
                        console.log(e);
                        const errorPayload = {
                            type: 'ERROR',
                            message: e
                        }
                        dispatch(utilityActions.toast(errorPayload))
                        dispatch(utilityActions.showToast(true))
                        throw Error(e)
                    });
}
function custom() {
    return (url, options, dispatchAction, dispatch) => {
        const END_POINT = new URL(url)
        return client(END_POINT, options, dispatchAction, dispatch)
    }
}

function request(method) {
    return (url, body, dispatchAction, dispatch) => {
        const END_POINT = new URL(url)
        dispatch(utilityActions.loading(true))
        const requestOptions = {
            method,
            headers: authHeader()
        };

        if (body) {
            if(method === 'GET') {
                END_POINT.search = new URLSearchParams(body).toString()
            } else {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }
        }

        return client(END_POINT, requestOptions, dispatchAction, dispatch)
    }
}

function authHeader() {
    const token = authToken()
    if(token) {
        return { 
            credentials: 'include',
            'Authorization': authToken()
        };
    }
    return {}
}

function authToken() {
    return store.getState().auth.user?.access_token;
}

function safeParse(data) {
    try {
        return JSON.parse(data)
    } catch {
        console.log(data)
        console.log('Ignoring json data')
        return {}
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && safeParse(text);
        if(response && response.url && response.url.includes('storage.googleapis.com') && !response.ok) {
            const error = "Unable to upload the schedule";
            return Promise.reject(error);
        } else if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export { authHeader }