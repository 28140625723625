
export const headerMapping = {
    'manage-student': 'Manage Students',
    'schedule': 'Schedule',
    'payments': 'Payments',
    'accounts': 'My Account',
    'subscription': 'SubscriptionManager',
    'events': 'Events Manager'
}

export const badgeColor = {
    'Little Tiger': 'indigo',
    'Youth Program': 'pink',
    'Adult Program': 'success'
}

const ENROLLMENTS = {
    'DRAFT': {
        color: 'gray',
        display: 'Draft',
        value: 'DRAFT'
    },
    'ACTIVE': {
        color: 'success',
        display: 'Active',
        value: 'ACTIVE'
    },
    'PENDING_APPROVAL': {
        color: 'indigo',
        display: 'Approval Pending',
        value: 'PENDING_APPROVAL'
    },
    'PAUSED': {
        color: 'yellow',
        display: 'Paused',
        value: 'PAUSED'
    }
}

export const utilities = {
    prettyFormatNumber: (v) => prettyFormatNumber(v),
    capitalize: (v) => capitalize(v),
    getProgramByAge: (v) => getProgramByAge(v),
    getAge: (v) => getAge(v),
    getPlanStatus: (v) => getPlanStatus(v),
    badgeColor: badgeColor
}

const getPlanStatus = (enrollment) => {
    if(!enrollment || !enrollment.enrollmentStatus) 
        return ENROLLMENTS['DRAFT']
    
    return ENROLLMENTS[enrollment.enrollmentStatus]
}

const prettyFormatNumber = (value) => {
    if (value === 0) 
        return "0"
    if (value < 10) {
        return "0" + value
    }
    return value
}

const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1)

const calculateAge = (value) => {
    const diff_ms = Date.now() - value.getTime();
    const age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const getProgramByAge = (date) => {
    const value = calculateAge(date)
    if (value <= 5) {
        return 'Little Tiger'
    } else if ( 5 < value && value <= 18) {
        return 'Youth Program'
    }
    return 'Adult Program'
}

const getAge = birthDate => birthDate ? Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10) : 0

const formatPhoneNumber = (phoneNumberString) => {
    if(!phoneNumberString) return <span>&nbsp;&nbsp;&nbsp;&nbsp;-</span>
    const cleaned = phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/,"($1)$2-$3");
    if(cleaned) return cleaned ?? <span>&nbsp;&nbsp;&nbsp;&nbsp;-</span>
    return phoneNumberString ?? <span>&nbsp;&nbsp;&nbsp;&nbsp;-</span>
}

const conditions = [
    "The terms and fees of belt testing have been explained to me.",
    "I understand the class schedule may change.",
    "I understand that if I or my child is sick, it is okay to stay home until I/my child gets better. I know that I/my child can make up those classes as long as I am currently enrolled.",
    "I understand that moving (relocating your home) more than 25 miles or a medical disability (physically unable to perform or join the Tae Kwon Do classes) are the only two reasons that my enrollment may be canceled with USAePay (written proof of moving is required). A one-month cancellation notice for any reason other than medical or relocation may be applied.",
    "This contract will automatically renew month to month unless proper 30 days' notice is given.",
    "A written 30-day cancellation notice must be sent via email to info@masterminstkd.com. Once the 30-day notice is received, payment will continue for one more billing cycle to complete your obligation.",
    "No prorations will be applied to any monthly bills.",
    "In the event that any check or other order for the payment of money is returned unpaid for any reason, I need to pay the returned payment charges of $20.",
    "By initialing the above boxes, I am stating that I understand the information above and that any questions I had were answered to my satisfaction."
]

const APPROVAL_MODAL='APPROVAL_MODAL'
const MANAGE_MEMBERS_MODAL='MANAGE_MEMBERS_MODAL'
const USER_MODAL='USER_MODAL'


const plans = [
    {   
        display: "Basic",
        value: "basic",
    }, {   
        display: "Premium",
        value: "premium",
    }, {   
        display: "Month to Month",
        value: "monthtomonth",
    }
]

const isAdmin = (userDetails) => {
    return userDetails && userDetails.roles && userDetails.roles.includes("ADMIN")
}

const safeParseDateAsIsoString = (dateString) => {
    if(!dateString) return ''
    return new Date(dateString).toISOString().split("T")[0]
}

const paddingNumber = (value) => {
    return value < 10 ? "0" + value : value
}

const formatDate = (value) => {
    const date = new Date(value)
    return [ paddingNumber(date.getMonth()+1), paddingNumber(date.getDate()), date.getFullYear()].join('/')
}

export { conditions, formatPhoneNumber, APPROVAL_MODAL, MANAGE_MEMBERS_MODAL, USER_MODAL, plans, getPlanStatus, isAdmin, safeParseDateAsIsoString, formatDate }