import { useDispatch, useSelector } from "react-redux";
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import { authActions } from "../../_store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Spinner } from 'flowbite-react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpBox from "../../components/otp-box/OtpBox";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { IconButton } from "@material-tailwind/react";

const Resetpassword = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [value, setValue] = useState('')
    const [toggle, setToggle] = useState(false)
    const [resetToggle, setResetToggle] = useState(false)

    let validationSchema = null;
    const { error: authError } = useSelector(state => state.auth)

    const { resetPasswordSteps } = useSelector(state => state.auth)

    useEffect(() => {
        if(resetPasswordSteps === 'PASSWORD_RESET_SUCCESS') {
            navigate("/")
        }
        return () => {}
    }, [resetPasswordSteps])


    // generate code submission
    const onSubmit = ({ email }) => {
        return dispatch(authActions.generatecode({ email }));
    }

    // form validation rules 

    if(resetPasswordSteps === 'VERIFY_PENDING') {
    
        validationSchema = Yup.object().shape({
            email: Yup.string().required('Email is required')
        });
    }
    if(resetPasswordSteps === 'VERIFY_PASSWORD') {
    
        validationSchema = Yup.object().shape({
            password: Yup.string().required('Password is required'),
            confirmPassword: Yup.string().required('Confirm Password is required')
                                .oneOf([Yup.ref("password")], "Passwords do not match"),
            otp: Yup.string().required('OTP is required').length(4, 'OTP is required')
        });
    }
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);

    
    const { errors, isSubmitting } = formState;


    // password reset submission
    const onResetPasswordSubmit = (data) => {
        console.log(data)
        const payload = {
            email: data.email,
            password: data.password,
            otp: data.otp
        }
        return dispatch(authActions.resetpassword(payload))
    }
    
    return <AuthWrapper page={'customer_login'}>
        {resetPasswordSteps === 'VERIFY_PENDING' &&
            <div className="flex flex-col w-[26rem]">
                <div className="px-6 space-y-4 md:space-y-6 sm:px-8">
                    
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
                        Forgot password?
                    </h1>
                    <p className="text-sm font-light text-gray-500 text-center">
                    No worries, we'll send you reset instructions 
                    </p>



                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="py-4 space-y-4 md:space-y-6" action="#">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-extrabold">Email</label>
                                <input type="email" name="email" id="email" {...register('email')} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="user@gmail.com" required="" />
                                <p className="text-red-600 mt-2 text-xs">{errors.email?.message}</p>
                            </div>
                            {
                                authError && authError.message &&
                                <div className="mx-auto w-[80%] p-2 border-red-500 border border-l-4 flex items-center justify-center"> 
                                <svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                
                                    <p className="text-xs text-center text-black-600 w-full">
                                        {authError.message}
                                    </p>
                                </div>
                            }
                            <button disabled={isSubmitting} className="w-full text-white bg-black font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {
                                    isSubmitting ? 
                                        <Spinner />
                                        : 
                                        "Reset password"
                                }
                            </button>
                            <div className="text-center">
                            <div className="flex items-center justify-center">
                                <div className="items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="ml-3">
                                <a href="/login" className="text-sm font-medium text-primary-600 hover:underline">Back to log in</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>}
            {resetPasswordSteps === 'VERIFY_PASSWORD' &&
            <div className="flex flex-col w-[26rem]">
            <div className="px-6 space-y-4 md:space-y-6 sm:px-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
                    Set new password
                </h1>
                <p className="text-sm font-light text-gray-500 text-center">
                    Must be at leaset 8 characters    
                </p>

                <form onSubmit={handleSubmit(onResetPasswordSubmit)}>
                    <div className="py-4 space-y-4 md:space-y-6" action="#">
                        
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Enter a new Password</label>
                            <div className="flex">
                                <input type={toggle ? "text" : "password" } id="password" {...register('password',{ required: true })} placeholder="••••••••••••••••" className="bg-gray-50 border border-gray-300 rounded-r-none text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required="" />
                                <IconButton onClick={() => setToggle(!toggle)} variant="outlined" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-l-none p-[1.25rem]">
                                    {toggle ? <IoEyeOutline/> : <IoEyeOffOutline />}
                                </IconButton>
                            </div>
                            <p className="text-red-600 mt-2 text-xs">{errors.password?.message}</p>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Re-type confirm new Password</label>
                            <div className="flex">
                                <input type={resetToggle ? "text" : "password" } id="confirmPassword" {...register('confirmPassword', { required: true })} placeholder="••••••••••••••••" className="bg-gray-50 border border-gray-300 rounded-r-none text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required="" />
                                <IconButton onClick={() => setResetToggle(!resetToggle)} variant="outlined" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-l-none p-[1.25rem]">
                                    {resetToggle ? <IoEyeOutline/> : <IoEyeOffOutline />}
                                </IconButton>
                            </div>
                            <p className="text-red-600 mt-2 text-xs">{errors.confirmPassword?.message}</p>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">OTP</label>
                            <OtpBox
                                onChange={(value) => {
                                    console.log(value);
                                    setValue('otp', value);
                                }}
                            />
                            <p className="text-red-600 mt-2 text-xs">{errors.otp?.message}</p>
                        </div>
                        <button disabled={isSubmitting} className="w-full text-white bg-black font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            {
                                isSubmitting? 
                                    <Spinner />
                                    : 
                                    "Reset password"
                            }
                        </button>
                        <div className="text-center">
                                <div className="flex items-center justify-center">
                                    <div className="items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clipRule="evenodd" />
                                    </svg>
                                    </div>
                                    <div className="ml-3">
                                    <a href="/login" className="text-sm font-medium text-primary-600 hover:underline">Back to log in</a>
                                    </div>
                                </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
            }
    </AuthWrapper>
}

export default Resetpassword