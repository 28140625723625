import TopNav from "../../components/topnav/TopNav"
import SecurityInfo from "../accounts/sections/SecurityInfo"

const Settings = () => {
    return <div>
        <TopNav />
        <div className="w-[70%] m-auto mt-6">
            <SecurityInfo />
        </div>
    </div>
}

export default Settings