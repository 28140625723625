import { useDispatch, useSelector } from "react-redux"
import SectionCard from "../../../components/section-card/SectionCard"
import { useEffect, useState } from "react"
import { paymentActions } from "../../../_store"
import { RiExchangeDollarFill } from "react-icons/ri";
import PlanModal from "../../../components/plan-modal/PlanModal"
import {Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { AiFillCheckCircle } from "react-icons/ai";
import { formatDate } from "../../../_helpers";

const Versions = (props) => {
    const { plansVersions: plansVersions } = useSelector(state => state.payment)
    const { user: user } = useSelector(state => state.user)
    const [selected, setSelected] = useState(null)
    const [open, setOpen] = useState(null);
    const [openAccordian, setOpenAccordian] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(paymentActions.getPlanVersions())
    }, [])

    const activatePlan = (versionId) => {
        console.log(versionId)
        const payload = {
            planVesrionId: versionId
        }
        dispatch(paymentActions.activatePlan(payload))
    }

    const addButton = () => {
        return <div onClick={() => setOpen(true)} className="px-4 py-2 text-center text-xs text-gray-500 border rounded-2xl rounded hover:cursor-pointer hover:bg-gray-50"> 
                    Add +
                </div>
    }

    const sortVersions = (versions) => {
        // versions.sort((a, b) => a.id > b.id ? 1 : -1)
        const sortedVersion = [ ...versions ]
        return sortedVersion.sort((a,b) => a.price - b.price)
    }
    
    return <SectionCard 
                classNames="overflow-scroll	max-h-100"
                showEditable={false}
                headerIcon={<RiExchangeDollarFill className="text-blue-800"/>} 
                title={"Subscription Plan"} 
                action={addButton()}
                >
                    {open ? <PlanModal open={open} setOpen={setOpen}/> : <></>}
                    <div className="grid grid-cols-1 gap-3">
                        {plansVersions && plansVersions.map((version, i) => <div key={i}>
                                    <Accordion open={openAccordian === i}>
                                        <AccordionHeader className="px-5 border rounded-t-lg shadow text-gray-800" onClick={() => setOpenAccordian(i)}>
                                            <div className="text-sm">
                                                <div>Plan - {i + 1}</div>
                                                <div className="text-xs text-gray-400 font-medium">
                                                    Created At : {formatDate(version.createdAt)} {new Date(version.createdAt).toLocaleTimeString('en-US')}
                                                </div>
                                            </div>
                                            <div className="flex items-center ml-auto mr-8">
                                                { version.status  ? 
                                                    <div className="flex items-center text-xs"> <AiFillCheckCircle className="text-green-500 text-lg"/> <span className="hidden sm:block">Active</span> </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            
                                        </AccordionHeader>
                                        <AccordionBody className="bg-gray-50 rounded-b-lg border">
                                            <div className="flex flex-col w-[40%] m-auto font-semibold">
                                                <div className="grid grid-cols-2 gap-2 	border-b-2">
                                                    <label className="block mb-2 text-sm text-gray-900">Plan Name</label>
                                                    <label className="text-center">Price</label>
                                                </div>

                                            { 
                                                version.plans && sortVersions(version.plans).map((v, i) => 
                                                    <>
                                                        <div key={i} className="grid grid-cols-2 gap-2">
                                                            <label className="block mb-2 text-sm font-medium text-gray-900">{v.display_name} Plan</label>
                                                            <label className="text-center">{v.price} $</label>
                                                        </div>
                                                    </>)
                                                
                                            }
                                            { !version.status  ? <button className="text-blue-500 text-xs hover:underline mt-4" onClick={() => activatePlan(version.id)} > Set Active </button> : <></>}
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                
                        </div>)}

                    </div>
        </SectionCard>
}

export default Versions