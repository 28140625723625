import TopNav from "../../components/topnav/TopNav"
import Versions from "./sections/Versions"

const SubscriptionManager = () =>  {
    return <div>
        <TopNav />
        <div className="w-[85%] sm:w-[70%] m-auto mt-6">
            <div>
                <Versions/>
            </div>
        </div>
    </div>
}

export default SubscriptionManager