import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authHeader, fetchWrapper, utilities } from '../_helpers';

const name='utility'
const initialState = { loading: 0, activePage: 'Home', schedule: null, toast: {}, showToast: false }

const extraActions = createExtraActions();

const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    loading(state, action) {
        state.loading = action.payload
    }, 
    activePage(state, action) {
      state.activePage = action.payload
    },
    toast(state, action) {
      // Object.assign(state.toast, action.payload)
      state.toast = {}
      Object.assign(state.toast, action.payload) 
    },
    showToast(state, action) {
      state.showToast = action.payload
  },
  },
  extraReducers: createExtraReducers(),
})

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/auth/schedule`;
  
  return {
      uploadFile: uploadFile(),
      getSchedule: getSchedule(),
      updateSchedule: updateSchedule()
  };    

    function getSchedule() {
        return createAsyncThunk(
            `${name}/getSchedule`,
            async (data, { dispatch }) => await fetchWrapper.get(baseUrl, data, null, dispatch)
        );
    }

    function updateSchedule() {
        return createAsyncThunk(
            `${name}/updateSchedule`,
            async (data, { dispatch }) => {
                const action = () => dispatch(utilityActions.getSchedule())
                await fetchWrapper.post(baseUrl, data, action, dispatch)
            }
        );
    }
  function uploadFile() {
    return createAsyncThunk(
      `${name}/uploadFile`,
      async (data, { dispatch }) => {
        const options = {
            method: 'POST',
            body: data,
            headers: {
                ...authHeader()
            }
        };

        return await fetchWrapper.custom(baseUrl, options, () => dispatch(utilityActions.getSchedule()), dispatch)
      }
    );
  }
}


function createExtraReducers() {
  return {
    ...getSchedule(),
  };

  function getSchedule() {
      var { pending, fulfilled, rejected } = extraActions.getSchedule;
      return {
          [pending]: (state) => {
              state.error = null;
          },
          [fulfilled]: (state, action) => {
              const schedule = action.payload;
              state.schedule = schedule;
          },
          [rejected]: (state, action) => {
              state.error = action.error;
          }
      };
  }
}
export const utilityActions = { ...utilitySlice.actions, ...extraActions };
export const utilityReducer =  utilitySlice.reducer