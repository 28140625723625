import { useEffect, useRef, useState } from "react"
import TopNav from "../../components/topnav/TopNav"
import { Button, Card } from "@material-tailwind/react"
import Uploader from "./sections/Uploader"
import { useDispatch, useSelector } from "react-redux"
import { utilityActions } from "../../_store"
import { isAdmin } from "../../_helpers"


const Schedule = () => {

    const dispatch = useDispatch()
    const { schedule: schedule } = useSelector(state => state.utility)
    const {user: userDetails} = useSelector(state => state.user)
    const [showUploader, setShowUploader] = useState(null)

    useEffect(() => {
        dispatch(utilityActions.getSchedule())
    }, [])

    const getActions = () => {
        if(isAdmin(userDetails)) {
            return <Button type="submit" onClick={() => setShowUploader(!showUploader)}>Upload</Button>
        }
    }

    return <div>
        <TopNav actions={getActions()}/>
        <div className="w-[95%] m-auto mt-6 flex justify-center h-full">
            {schedule && schedule.filePath ?
            <img src={`${schedule.filePath}?${new Date().getTime()}`} />
            : <Card className="w-full p-10 text-center"> No Schedules found</Card>}
        </div>
        {showUploader && <Uploader action={() => setShowUploader(!showUploader)}/>}
    </div>
}

export default Schedule