import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import { authActions } from '../../_store';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import { Spinner } from 'flowbite-react';
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { IconButton } from "@material-tailwind/react";
import { useState } from "react";

const Signup = (props) => {

    const dispatch = useDispatch()
    const [toggle, setToggle] = useState(false)
    const [resetToggle, setResetToggle] = useState(false)

    const { error: authError } = useSelector(state => state.auth)

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        first_name: Yup.string().required('Frist name is required'),
        last_name: Yup.string().required('Last name is required'),
        dob: Yup.string().required('Date of birth is required'),
        address: Yup.string().required('Address is required'),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        zipcode: Yup.string().required('Zip Code is required'),
        phoneno: Yup.string().required('Contact Number is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Confirm Password is required')
                            .oneOf([Yup.ref("password")], "Passwords do not match"),
        condition: Yup.bool().oneOf([true], 'Please accept Terms & conditions to proceed')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const submitAction = async (data) => {
        console.log(data)
        const payload = {
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            password: data.password,
            address: data.address,
            state: data.state,
            gender: data.gender,
            city: data.city,
            zipcode: data.zipcode,
            phoneno: data.phoneno,
            employment: data.employment,
            dob: data.dob
        }
        console.log(payload)
        const resp = await dispatch(authActions.register(payload))
    }

    const common_style = "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 1);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return <AuthWrapper page={'customer_login'} classNames='!mb-0'>
            <form onSubmit={handleSubmit(submitAction)}>
                <div className="flex flex-col w-full md:w-[48rem]">
                    <div className="px-6 space-y-4 md:space-y-6 sm:px-8">
                        <p className="text-sm font-light text-gray-500 text-end">
                            Already have an account ? <a href="/signin" className="font-medium text-blue-800 hover:underline">Login</a>
                        </p>
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Create an account
                        </h1>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                                <input type="email" id="email" {...register('email')} className={common_style} placeholder="name@company.com" required="" />
                                <p className="text-red-600 mt-2 text-xs">{errors.email?.message}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">First name</label>
                                    <input type="text"  id="name" {...register('first_name')} className={common_style} placeholder="First name" required="" />
                                    <p className="text-red-600 mt-2 text-xs">{errors.first_name?.message}</p>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Last name</label>
                                    <input type="text"  id="name" {...register('last_name')} className={common_style} placeholder="Last name" required="" />
                                    <p className="text-red-600 mt-2 text-xs">{errors.last_name?.message}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Date of birth</label>
                                    <Controller
                                        control={control}
                                            name='dob'
                                            render={({ field }) => (
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                        </select>
                                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                            {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                        </select>
                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                    </div>
                                                )}
                                                showIcon
                                                icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                                className='block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-gray-300 dark:focus:ring-gray-300 !p-2.5 text-sm rounded-lg' 
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText='MM/DD/YYYY'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                            />
                                        )}
                                    />
                                    {/* <input {...register('dob')} pattern="\d{4}-\d{2}-\d{2}" className={common_style} type="date"/> */}
                                    <p className="text-red-600 mt-2 text-xs">{errors.dob?.message}</p>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Gender</label>
                                    <select {...register('gender')}  id="gender" className={common_style} required >
                                        <option value={'Male'}> Male </option>
                                        <option value={'Female'}> Female </option>
                                        <option value={'Others'}> Others </option>
                                    </select>
                                    <p className="text-red-600 mt-2 text-xs">{errors.gender?.message}</p>
                                </div>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Address</label>
                                <textarea {...register('address')}  className={common_style} required rows={4} type="text" placeholder="Address"/>
                                <p className="text-red-600 mt-2 text-xs">{errors.address?.message}</p>
                            </div>
                            <div className="grid grid-cols-3 gap-4 mb-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900">State</label>
                                    <input {...register('state')} className={common_style} type="text" placeholder="State"/>
                                    <p className="text-red-600 mt-2 text-xs">{errors.state?.message}</p>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900">City</label>
                                    <input {...register('city')} className={common_style}  type="text" placeholder="City"/>
                                    <p className="text-red-600 mt-2 text-xs">{errors.city?.message}</p>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Zipcode</label>
                                    <input {...register('zipcode')} className={common_style} type="text" placeholder="Zip"/>
                                    <p className="text-red-600 mt-2 text-xs">{errors.zipcode?.message}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Phone number</label>
                                    <input {...register('phoneno')} className={common_style} type="text" />
                                    <p className="text-red-600 mt-2 text-xs">{errors.phoneno?.message}</p>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Employer & Position</label>
                                    <input {...register('employment')} className={common_style} type="text" />
                                    <p className="text-red-600 mt-2 text-xs">{errors.employment?.message}</p>
                                </div>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                <div className="flex">
                                    <input type={toggle ? "text" : "password" } id="password" {...register('password')} placeholder="••••••••••••••••" className={`${common_style} rounded-r-none`} required="" />
                                    <IconButton onClick={() => setToggle(!toggle)} variant="outlined" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-l-none p-[1.25rem]">
                                        {toggle ? <IoEyeOutline/> : <IoEyeOffOutline />}
                                    </IconButton>
                                </div>
                                <p className="text-red-600 mt-2 text-xs">{errors.password?.message}</p>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Confirm password</label>
                                <div className="flex">
                                    <input type={resetToggle ? "text" : "password" } id="confirmPassword" {...register('confirmPassword')} placeholder="••••••••••••••••" className={`${common_style} rounded-r-none`} required="" />
                                    <IconButton onClick={() => setResetToggle(!resetToggle)} variant="outlined" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-l-none p-[1.25rem]">
                                        {resetToggle ? <IoEyeOutline/> : <IoEyeOffOutline />}
                                    </IconButton>
                                </div>
                                <p className="text-red-600 mt-2 text-xs">{errors.confirmPassword?.message}</p>
                            </div>
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="terms" type="checkbox" {...register('condition')} className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label className="font-light text-gray-500">I accept the <a className="font-medium text-primary-600 hover:underline" href="#">Terms and Conditions</a></label>
                                    <p className="text-red-600 mt-2 text-xs">{errors.condition?.message}</p>
                                </div>
                            </div>
                            {
                                authError && authError.message &&
                                <div className="mx-auto w-[80%] p-2 border-red-500 border border-l-4 flex items-center justify-center"> 
                                <svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                
                                    <p className="text-xs text-center text-black-600 w-full">
                                        {authError.message}
                                    </p>
                                </div>
                            }
                            <button  disabled={isSubmitting} className="w-full text-white bg-black font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                { isSubmitting ? <Spinner /> :  "Create an account" }
                            </button>
                    </div>
                </div>
            </form>
    </AuthWrapper>
}

export default Signup;