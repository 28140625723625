import { useDispatch, useSelector } from "react-redux";
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper"
import { authActions } from '../../_store';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Spinner } from 'flowbite-react';
import { IconButton } from "@material-tailwind/react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useState } from "react";

const Signin = () => {
    const dispatch = useDispatch();

    const { error: authError } = useSelector(state => state.auth)

    const [toggle, setToggle] = useState(false)

    const onSubmit = ({ email, password }) => {
        return dispatch(authActions.login({ email, password }));
    }

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    
    return <AuthWrapper page={'customer_login'}>
            <div className="flex flex-col w-[26rem]">
                <div className="px-6 space-y-4 md:space-y-6 sm:px-8">
                    <p className="text-sm font-light text-gray-500 text-end">
                            Don’t have an account yet? <a href="/signup" className="font-medium text-blue-800 hover:underline">Sign up</a>
                    </p>
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        Sign in to your account
                    </h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="py-4 space-y-4 md:space-y-6" action="#">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-extrabold">Email</label>
                                <input type="email" name="email" id="email" {...register('email')} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="user@gmail.com" required="" />
                                <p className="text-xs text-red-600 mt-2">{errors.email?.message}</p>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-extrabold">Password</label>
                                <div className="flex">
                                    <input type={toggle ? "text" : "password" } name="password" id="password" {...register('password')} placeholder="•••••••••••••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg rounded-r-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required="" />
                                    <IconButton onClick={() => setToggle(!toggle)} variant="outlined" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-l-none p-[1.25rem]">
                                        {toggle ? <IoEyeOutline/> : <IoEyeOffOutline />}
                                    </IconButton>
                                </div>
                                <p className="text-xs text-red-600 mt-2">{errors.password?.message}</p>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label className="text-gray-500">Remember me</label>
                                    </div>
                                </div>
                                <a href="/resetpassword" className="text-sm font-medium text-primary-600 hover:underline">Forgot password?</a>
                            </div>
                            {
                                authError && authError.message &&
                                <div className="mx-auto w-[80%] p-2 border-red-500 border border-l-4 flex items-center justify-center"> 
                                <svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                
                                    <p className="text-xs text-center text-black-600 w-full">
                                        {authError.message}
                                    </p>
                                </div>
                            }
                            <button disabled={isSubmitting} className="w-full text-white bg-black font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {
                                    isSubmitting ? 
                                        <Spinner />
                                        : 
                                        "Login"
                                }
                            </button>
                        
                    </div>
                    </form>
                </div>
            </div>
    </AuthWrapper>
}

export default Signin