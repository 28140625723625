import { useEffect, useState } from "react";
import { GrSchedulePlay } from "react-icons/gr"
import { Label } from 'flowbite-react';
import { useDispatch, useSelector } from "react-redux";
import { useForm, reset, Controller } from "react-hook-form";
import SectionCard from "../section-card/SectionCard";
import { ordersActions } from "../../_store";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";

const ManagePaymentSchedule = (props) => {
    
    const dispatch = useDispatch()

    const [enrollment, setEnrollment] = useState({})
    const [userId, setUserId] = useState([])

    useEffect(() => {
        if(props.enrollment) {
            setEnrollment(props.enrollment)
        }
    }, [props.enrollment])

    useEffect(() => {
        if(props.userId) {
            setUserId(props.userId)
        }
    }, [props.userId])

    const [editable, setEditable] = useState(false); 

    // form validation rules 
    // const validationSchema = Yup.object().shape({
    //     noOfPayments: Yup.string().required('noOfPayments is required'),
    // });

    const differenceInMonths = (date1, date2) => {
        const monthDiff = date1.getMonth() - date2.getMonth();
        const yearDiff = date1.getYear() - date2.getYear();
      
        return monthDiff + yearDiff * 12;
    }


    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() is zero-based, so we add 1
        const day = date.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        return formattedDate
    }

    const getFormattedDateFromString = (dateStr) => {
        if(dateStr) {
            const dateParts = dateStr.split('T')[0].split('-');
            const localDate = new Date(
                parseInt(dateParts[0]),  // Year
                parseInt(dateParts[1]) - 1,  // Month (zero-based)
                parseInt(dateParts[2])   // Day
            );
            return localDate
        }
        return dateStr
    }

    const defaultValue = {
        noOfPayments: enrollment.noOfPayments ?? 0,
        paymentStartDate: enrollment.paymentDate ? getFormattedDateFromString(enrollment.paymentDate) : enrollment.paymentDate,
        noOfPaymentsDerived: enrollment.noOfPayments ?? 0,
        paymentStartDateDerived: enrollment.paymentDate ? new Date(enrollment.paymentDate).toISOString().split('T')[0] : enrollment.paymentDate,
        discount: enrollment.discount ?? 0,
        charges: enrollment.additionalCharges ?? 0
    }

    useEffect(() => {
        reset(defaultValue)
    }, [enrollment]);
    

    const formOptions = { defaultValues: defaultValue };

    const { register, handleSubmit, formState, reset, setValue, getValues, watch, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = async (data) => {
        const formattedDate = getFormattedDate(data.paymentStartDate)
        console.log(formattedDate); // Outputs: "YYYY-MM-DD"
        const payload = {
            payment_id: enrollment.id,
            discount: data.discount,
            no_of_payments: data.noOfPayments,
            enrollment_date: formattedDate,
            user_id: userId,
            "action": "update_props"
        }
        console.log(payload)
        const schedulePayload = {
            action: "all",
            user_id: userId
        }
        console.log(payload)
        
        const action = () => dispatch(ordersActions.getEnrollments(schedulePayload))

        const resp = await dispatch(ordersActions.updateEnrollments({ payload, action }))
        if (resp.meta.requestStatus === 'fulfilled') {
            setEditable(false)
        }
    }

    const disabled_theme = 'border-none w-full bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg cursor-not-allowed'

    const enabled_theme = 'w-full bg-gray-50 bg-white border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg'

    const childProps = {
        editable: editable,
        setEditable: () =>  {
            reset()
            setEditable(!editable)
        },
        saveAction: handleSubmit(onSubmit),
        headerIcon: <GrSchedulePlay/>,
        title: "Manage Schedule",
        isSubmitting: isSubmitting
    }

    const updateInput = (e) => {
        const id = e.target.id + 'Derived'
        setValue(id, e.target.value)
    }

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 2);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return <SectionCard {...childProps} showEditable={true}>
            <div className="py-4">
                <form >
                    <div className="grid grid-cols-4 gap-4 mb-4">
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="No of Payments" />
                            </div>
                            <input onInput={(e) => updateInput(e)} id='noOfPayments' {...register('noOfPayments')} className={`${editable ? enabled_theme : disabled_theme}`} type="number" placeholder="0"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Schedule start date" />
                            </div>
                            <Controller
                                    control={control}
                                    name='paymentStartDate'
                                    render={({ field }) => (
                                        <DatePicker
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                    </select>
                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                        {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                    </select>
                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                </div>
                                            )}
                                            showIcon
                                            icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                            className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText='MM/DD/YYYY'
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                        />
                                    )}
                                />
                        </div>
                        {/* <div>
                            <div className="block">
                                <Label htmlFor="small" value="Payments remainig" />
                            </div>
                            <input {...register('noOfPaymentsDerived')} disabled={true} className={`${disabled_theme}`} type="number" placeholder="0"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Next Schedule date" />
                            </div>
                            <input {...register('paymentStartDateDerived')} pattern="\d{4}-\d{2}-\d{2}" disabled={true} className={`${disabled_theme}`} type="date"/>
                        </div> */}
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Additional Discount ($)"/>
                            </div>
                            <input {...register('discount')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="number" placeholder="0"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Additional Charges ($)" />
                            </div>
                            <input {...register('charges')} disabled={true} className={`${disabled_theme}`} type="number" placeholder="0"/>
                        </div>
                    </div>
                </form>
            </div>     
        </SectionCard>
}

export default ManagePaymentSchedule

