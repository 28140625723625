import { useState } from "react";
import { utilities } from "../../_helpers"
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";

const EnrollmentSummary = (props) => {

    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return <div className={`sm:mx-20 p-6 border bg-white overflow-scroll ${props.classNames}`}>
    <div className="pb-8 border-b">
        <div className="text-gray-600 text-center font-semibold text-3xl">
            Order Summary
        </div>
    </div>
    <div className="pt-5 border-b">
        <div className="grid grid-cols-4 gap-4 text-gray-600">
            <div className="col-span-2 underline">Description</div>
            <div className="underline text-center">Count</div>
            <div className="underline text-center">Amount</div>
        </div>
        {['basic', 'premium', 'monthtomonth'].map(k => props.summary && props.summary[k] && <div className="py-2">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2">{props.summary[k].plan.display_name} Program SubscriptionManager </div>
                        <div className="text-center">{utilities.prettyFormatNumber(props.summary[k].no_of_license)} </div>
                        <div className="text-center">${props.summary[k].actual_cost} </div>
                    </div>
        </div>)}

        {props.members && <div className="pt-2 border-t">
        <Accordion open={open === 1} className="mb-2 rounded-lg border border-blue-gray-100 px-4" >
            <AccordionHeader className="border-b-0 text-lg text-gray-500" onClick={() => handleOpen(1)}>Requested members</AccordionHeader>
            <AccordionBody className="pt-0">
            <div className="grid grid-cols-4 gap-4 font-semibold">
                <div className="text-gray-500">Member</div>
                <div>Relationship</div>
                <div className="text-center">Program</div>
            </div>
            {props.members.map(member => 
                <div className="grid grid-cols-4 gap-4">
                    <div className="text-gray-500">{member.name}</div>
                    <div>{member.relationship}</div>
                    <div className="text-center">{member.plan.display_name} </div>
                </div>)}
            </AccordionBody>
        </Accordion>
        </div>}
    </div>
    <div className="py-3 border-b">
        <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2 text-gray-500">Sub - Total</div>
                <div></div>
                <div className="text-center">${props.summary.sub_total} </div>
        </div>
        <div className="grid grid-cols-4 gap-4 text-gray-500">
            <div className="col-span-2"> Member Discount </div>
            <div></div>
            <div className="text-center"> (-)${props.summary.overall_discount} </div>
        </div>
    </div>
    <div className="py-3 border-b">
        <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2 text-gray-500">Additional Discount</div>
                <div></div>
                <div className="text-center"> (-)${props.summary.coupon_discount ?? 0} </div>
        </div>
        <div className="grid grid-cols-4 gap-4 text-gray-500">
            <div className="col-span-2"> Additional Charges</div>
            <div></div>
            <div className="text-center"> (+)${props.summary.additional_charges} </div>
        </div>
    </div>
    <div className="pt-5">
        <div className="grid grid-cols-4 gap-4 text-xl font-semibold">
            <div className="col-span-2">Total </div>
            <div></div>
            <div className="text-center">${props.summary.amount} </div>
        </div>
    </div>
    <div>
        {props.children}
    </div>
</div>
}

export default EnrollmentSummary