import { useDispatch, useSelector } from "react-redux"
import SectionCard from "../../../components/section-card/SectionCard"
import { GrTransaction } from "react-icons/gr"
import { useEffect, useState } from "react"
import { paymentActions } from "../../../_store"
import { object } from "yup"


const PaymentSummary = () => {
	const { paymentTransactions: transactions } = useSelector(state => state.payment)
	const dispatch = useDispatch()
	useEffect(() => {
        dispatch(paymentActions.getPaymentTransactions())
    }, [])

	const getStatusStyle = (status_code) => {
		const statusStyles = {
		  S: 'text-green-500',
		  E: 'text-red-500',
		  V: 'text-red-500',
		  R: 'text-red-500',
		  T: 'text-red-500',
		  M: 'text-yellow-500',
		  H: 'text-yellow-500',
		};
	  
		return statusStyles[status_code];
	  };
	  
	  const renderTransaction = (transaction) => {
		const textColor = getStatusStyle(transaction.status_code);
		if(textColor){
		return (
		  <div className={`flex text-block py-4 px-2 border-b`}>
			<div className="w-1/2">
			  Batch Autopayments :: {transaction.refnum}
			  <div><span className={textColor}>payment </span>{transaction.created}</div>
			</div>
			<div className="w-1/2 flex justify-end flex-col items-end">
			  <div className={textColor}>${transaction.amount}</div>
			  <div className={textColor}>{transaction.status}</div>
			</div>
		  </div>
		);
		}
	  };
	
    return <SectionCard
                showEditable={false}
                headerIcon={<GrTransaction className="text-blue-800"/>} 
                title={"Payment Tranasctions"} 
                classNames="mt-6">
                <div className="min-h-full mb-8">
					{transactions && transactions.data && transactions.data.map(transaction => renderTransaction(transaction))}

					{!transactions ||  !transactions.data || transactions.data.length === 0 ?
						<div className="flex h-20 justify-center items-center text-gray-500">
							No transaction found
						</div> :
						<></>
					}
                </div>
        </SectionCard>
}

export default PaymentSummary