import { useState } from 'react';
import Logo from '../../img/logo.png';

const AuthWrapper = (props) => {
    const options = [{
        'key': 'customer_login',
        'value': 'Customer Login'
    }]
    const [showMenu, setShowMenu] = useState(false)
    
    return <div className='w-[95%] m-auto'>
                <div className={`grid grid-cols-2 h-[10%] mb-20 ${props.classNames}`}>
                    <div className="flex items-center hover:cursor-pointer h-14 sm:h-16">
                        <img className='w-48 sm:w-72' src={Logo} />
                    </div>
                    {/* <div className="text-l text-gray-600 flex justify-end items-center h-14 sm:h-16"> 
                        <div className='relative'>
                            <button id="dropdownDefaultButton" onClick={() => setShowMenu(!showMenu)} data-dropdown-toggle="dropdown" className="bg-gray-200 focus:ring-none focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                                    {options.find(option => option['key'] === props.page)['value']}
                                <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg></button>
                            <div id="dropdown" className={`${showMenu ? 'block' : 'hidden'} z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}>
                                <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                                    {options.map((option, i) => 
                                    <li key={i}>
                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100">{option.value}</a>
                                    </li>)}
                                </ul>
                            </div>
                        </div>

                    </div> */}
                </div>
                <div className='flex align-middle justify-center items-center h-[90%] overflow-scroll'>
                    <div className='p-4 border shadow'>
                        {props.children}
                    </div>
                </div>
            </div>
}

export default AuthWrapper