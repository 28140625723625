
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from "react-icons/hi"

const DeleteAlert = (props) => {

    const deleteAction = () => {
        props.confirmAction()
        props.setOpenDeleteModal(false)
    }

    return <Modal style={{ zIndex: "10000"}}  show={props.openModal} size="md" popup onClose={() => props.setOpenDeleteModal(false)}>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {props.msg ? props.msg : "Are you sure you want to delete this member" }
                    </h3>
                    <div className="flex justify-center gap-4">
                    <Button color="failure" onClick={() => deleteAction()}>
                        Yes, I'm sure
                    </Button>
                    <Button color="gray" onClick={() => props.setOpenDeleteModal(false)}>
                        No, cancel
                    </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
}

export default DeleteAlert