import { useDispatch } from "react-redux";
import TileCard from "../../components/title-card/TileCard"
import { useEffect } from "react";
import { dashboardActions, userActions } from "../../_store";
import { useSelector } from 'react-redux';
import { formatDate, utilities } from '../../_helpers'
import { Card, Typography, List, ListItem } from "@material-tailwind/react";
import { RiAccountPinCircleFill, RiCheckboxCircleFill } from "react-icons/ri"
import { FaUserPlus } from "react-icons/fa"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { MdCancel } from "react-icons/md"
import { FaUserGroup, FaUsersLine, FaUsers} from 'react-icons/fa6';
import TopNav from "../../components/topnav/TopNav";

const Home = () => {

    const dispatch = useDispatch();
    const currentDate = new Date()
    
    const { summary: accountSummary } = useSelector(state => state.dashboard);
    const {user: userDetails, feeds: feeds} = useSelector(state => state.user)

    useEffect(() => {
        dispatch(dashboardActions.summary());
        dispatch(userActions.getFeeds())
        return
    }, [])

    console.log(feeds)

    const FEEDS_ICON = {
        'SUCCESS': <RiCheckboxCircleFill className="text-green-500" />,
        'INFO': <AiOutlineInfoCircle className="text-yellow-500" />,
        'FAILURE': <MdCancel className="text-red-500" />,
    }
    
    const activites = [
        {
            icon: <RiCheckboxCircleFill className="text-green-500" />,
            header: "Payment completed",
            description: "Members have been enrolled to a program",
            time: "20:30PM"
        },
        {
        icon: <FaUserPlus />,
        header: "Member Created",
        description: "New Member has been created",
        time: "20:25PM"
        }, 
        {
        icon: <RiAccountPinCircleFill />,
        header: "User onboarding",
        description: "New User has been registered into the portal",
        time: "20:00PM"
        }, 
        {
        icon: <RiAccountPinCircleFill />,
        header: "User onboarding",
        description: "New User has been registered into the portal",
        time: "18:10PM"
    }]

    const titleCardTemplate = (value, title, icon) => {
        return <TileCard classNames="!bg-white">
                <div className="grid grid-cols-4 gap-6 h-full">
                    <div className="col-span-2 h-full flex items-center">
                        <div className="pl-6 font-semibold">
                            <div className="text-7xl">
                                {value}
                            </div>
                            <div className="text-4xl">
                                {title}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 flex items-end justify-end pr-2">
                        {icon}
                    </div>
                </div>
            </TileCard>
    }

    return <>
        <TopNav />
        <div className="w-[95%] m-auto mt-6 ">
            <div className="grid grid-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">
                    <div className="grid lg:grid-cols-2 gap-6">
                        {
                        userDetails && userDetails.roles && userDetails.roles.includes("ADMIN") && (<>
                            {titleCardTemplate(utilities.prettyFormatNumber(accountSummary.users), 'Families', <FaUsers className="text-gray-700 text-9xl"/>)}
                            {titleCardTemplate(utilities.prettyFormatNumber(accountSummary.members), 'Students', <FaUserGroup className="text-gray-700 text-9xl"/>)}
                        </>
                        )}
                        {
                        userDetails && userDetails.roles && userDetails.roles.includes("MEMBER") && (<>
                            {titleCardTemplate(utilities.prettyFormatNumber(accountSummary.members), 'Student', <FaUserGroup className="text-gray-700 text-9xl"/>)}
                            <TileCard classNames="!bg-white">
                                <div className="grid grid-cols-4 gap-6 h-full">
                                        <div className="pl-6 pt-4  col-span-2 h-full">
                                            <div className="font-semibold">
                                            <div className="text-4xl">
                                                    {currentDate.toLocaleString('en-us', {weekday:'long'})}
                                                </div>
                                                <div className="text-4xl">
                                                    {utilities.prettyFormatNumber(currentDate.getUTCDate())}
                                                </div>
                                            </div>
                                            <div className="mt-8 text-lg text-gray-500">
                                                No Events
                                            </div>
                                        </div>
                                    </div>
                            </TileCard>
                        </>
                        )}
                    </div>
                </div>
                <Card className="w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-scroll">
                    <div className="mb-2 p-4 border-b">
                        <Typography variant="h5" color="blue-gray">
                        Recent Activities
                        </Typography>
                    </div>
                    <div className="h-80 overflow-scroll">
                        {feeds && feeds.data.length > 0 ? 
                            feeds.data.map(feed => <ListItem className="flex justify-between space-y-8 bg-blue-gray-50 mb-8 h-24">
                                    <div className="w-4/6">
                                        <div className="text-black text-md flex font-semibold my-1 items-center">
                                            <div>{FEEDS_ICON[feed.type]}</div>
                                            <div className="pl-2">{feed.title}</div> 
                                        </div>
                                        <div className="text-sm font-thin">
                                            {feed.description}
                                        </div>
                                    </div>
                                    <div className="flex-1 text-end text-xs hover:underline">{formatDate(feed.updatedAt)} {new Date(feed.updatedAt).toLocaleTimeString('en-US')}</div>
                                </ListItem>)
                            : 
                            <div className="h-full flex justify-center items-center">
                                No recent activites
                            </div>
                        }
                    </div>
                </Card>
                {/* <div className="border-l-2 pl-6 pt-2">
                    <div className="font-bold text-lg">
                        Action Area
                    </div>
                    <div className="flex justify-center items-center h-full text-gray-600">
                        No Action required  
                    </div>
                </div> */}
            </div>
        </div>
    </>
}

export default Home