import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
// create slice

const name = 'dashboard';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });


// exports

export const dashboardActions = { ...slice.actions, ...extraActions };
export const dashboardReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        summary: {
            members: 0
        },
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user/dashboard`;

    return {
        summary: summary()
    };    

    function summary() {
        return createAsyncThunk(
            `${name}/summary`,
            async (value, { dispatch }) => await fetchWrapper.get(baseUrl, null, null, dispatch)
        );
    }
}

function createExtraReducers() {
    return {
        ...dashboard()
    };

    function dashboard() {
        var { pending, fulfilled, rejected } = extraActions.summary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const summary = action.payload;
                state.summary = summary;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
