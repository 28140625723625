import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import { membersActions } from './members.slice';
import { userActions } from './user.slice';
// create slice

const name = 'orders';
const initialState = createInitialState();
const reducers = createReducers()
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const ordersActions = { ...slice.actions, ...extraActions };
export const ordersReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        orders: null,
        orderSummary: null,
        showEnrollModal: false,
        enrollments: [],
        error: null,
    }
}

function createReducers() {
    return {
        showEnrollModal
    }

    function showEnrollModal(state, action) {
        state.showEnrollModal = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/enroll/program`;

    return {
        placeOrders: placeOrders(),
        orderSummary: orderSummary(),
        getEnrollments: getEnrollments(),
        reviewEnrollments: reviewEnrollments(),
        updateEnrollments: updateEnrollments()
    };    

    function placeOrders() {
        return createAsyncThunk(
            `${name}/placeOrders`,
            async (data, { dispatch }) => await fetchWrapper.post(baseUrl, data, () => dispatch(membersActions.members()), dispatch)
        );
    }

    function orderSummary() {
        return createAsyncThunk(
            `${name}/orderSummary`,
            async (data, { dispatch }) => await fetchWrapper.post(baseUrl + '/summary', data, null, dispatch)
        );
    }

    function getEnrollments() {
        return createAsyncThunk(
            `${name}/getEnrollments`,
            async (data, { dispatch }) => await fetchWrapper.post(baseUrl + '/review', data, null, dispatch)
        );
    }

    function reviewEnrollments() {
        return createAsyncThunk(
            `${name}/reviewEnrollments`,
            async (data, { dispatch }) => await fetchWrapper.post(baseUrl + '/review', data, () => dispatch(userActions.getAllUsers()), dispatch)
        );
    }

    function updateEnrollments() {
        return createAsyncThunk(
            `${name}/updateEnrollments`,
            async ({ payload, action}, { dispatch }) => {
                await fetchWrapper.post(baseUrl + '/review', payload, action, dispatch)
            }
        );
    }
}

function createExtraReducers() {
    return {
        ...orderSummary(),
        ...getEnrollments()
    };

    function orderSummary() {
        var { pending, fulfilled, rejected } = extraActions.orderSummary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const orderSummary = action.payload;
                state.orderSummary = orderSummary;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function getEnrollments() {
        var { pending, fulfilled, rejected } = extraActions.getEnrollments;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.enrollments = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
