import { useEffect, useState } from "react";
import SecurityCard from "../../../components/security-card/SecurityCard"
import { TfiReceipt } from "react-icons/tfi"
import { BsShieldLock } from "react-icons/bs"
import { Label, Select, TextInput, Textarea } from 'flowbite-react';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_store";
import { useForm, reset } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'; 
import * as Yup from 'yup';
import { authActions } from "../../../_store";


const SecurityInfo = () => {

    const dispatch = useDispatch();
    const {user: userDetails} = useSelector(state => state.user)


    const [editable, setEditable] = useState(false);

    const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required')
                        .oneOf([Yup.ref("password")], "Passwords do not match")
    });

    

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = async (data) => {
        console.log('Submitted');
        console.log("data",data);
        const payload = {
            email: userDetails.email,
            password: data.password
        }
        const resp = await dispatch(authActions.resetpassword(payload))
        console.log("response",resp);
        if (resp.meta.requestStatus === 'fulfilled') {
            setEditable(false)
        }
    }

    const disabled_theme = 'border-none w-full bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg cursor-not-allowed hidden'

    const enabled_theme = 'w-full bg-gray-50 bg-white border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg'

    const props = {
        editable: editable,
        setEditable: () => setEditable(!editable),
        saveAction: handleSubmit(onSubmit),
        headerIcon: <TfiReceipt className="text-blue-800"/>,
        title: "Login / Security",
        isSubmitting: isSubmitting
    }

    return <SecurityCard {...props}
                    showEditable={true}
                    headerIcon={<BsShieldLock className="text-blue-800"/>} 
                    title={"Login / Security"} >
            <div className="grid md:grid-cols-4 gap-4 mb-4">
                    <div className={`${editable ? enabled_theme : disabled_theme}`}>
                        <div className="block">
                            <Label htmlFor="small" value="New Password" />
                        </div>
                        <TextInput {...register('password',{ required: true })} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} id="password" color="gray" sizing="md" type="password" placeholder="••••••••••••••••"/>
                        <p className="text-red-600 mt-2">{errors.password?.message}</p>
                    </div>
                    <div className={`${editable ? enabled_theme : disabled_theme}`}>
                        <div className="block">
                            <Label htmlFor="small" value="Confirm Password" />
                        </div>
                        <TextInput id="confirmPassword" {...register('confirmPassword', { required: true })} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} color="gray" sizing="md" type="password" placeholder="••••••••••••••••"/>
                        <p className="text-red-600 mt-2">{errors.confirmPassword?.message}</p>
                    </div>
            </div>
    </SecurityCard>
}

export default SecurityInfo