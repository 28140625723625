import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { memberActions, membersActions, ordersActions } from "../../_store"
import { RiAddCircleFill } from "react-icons/ri";
import MemberForm from "./sections/MemberForm";
import Enroll from "./sections/Enroll";
import TopNav from "../../components/topnav/TopNav";
import InfoCard from "../../components/info-card/InfoCard";
import { Button, Tooltip, Typography } from "@material-tailwind/react";
import { getPlanStatus, isAdmin } from "../../_helpers";
import MemberTableView from "./sections/MemberTableView";
   
const Students = () => {
    const dispath = useDispatch()
    const { members: memberDetails } = useSelector(state => state.members)
    const {user: userDetails} = useSelector(state => state.user)

    const [openModal, setOpenModal] = useState(undefined);
    const dispatch = useDispatch()
    const [member, setEditMember] = useState(undefined)

    useEffect(() => {
        dispath(membersActions.members())
    }, [])

    const deleteMemeber = (value) => {
        const payload = { 'id': value }
        dispath(memberActions.deleteMember(payload))
    }

    const editMemeber = (id) => {
        setOpenModal(true)
        const member = Object.assign({}, memberDetails.find(m => m.id === id))
        console.log(member)
        setEditMember(member)
    }

    const allowEnrollment = () => {
        const status = memberDetails.map(m => getPlanStatus(m.programEnrollment).value)
        if(status.length === 0) return false
        if(status.includes('PENDING_APPROVAL')) return false
        if(!status.includes('DRAFT')) return false
        return true
    }
    
    return <>
            {isAdmin(userDetails) ? 
                    <>
                        <TopNav/>
                        <div className="w-[95%] m-auto mt-6">
                                <MemberTableView editAction={(editableId) => editMemeber(editableId)} memberDetails={memberDetails}/>
                        </div>
                    </>:
                    <>
                        <TopNav 
                            actions={<div>
                                {allowEnrollment() ?
                                    <Button  onClick={() => dispatch(ordersActions.showEnrollModal(true))} className="">
                                        Enroll to program
                                    </Button> :
                                    <Tooltip className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                                    content={<Typography className="w-40 text-black text-sm"> Previous enrollment yet to be apporved / No new members found</Typography>}>
                                        <Button variant="outlined" className="text-gray-400">
                                            Enroll to program
                                        </Button>
                                    </Tooltip>
                                }
                            </div>}
                        />
                        <div className="w-[95%] m-auto mt-6">
                            <div className="grid lg:grid-cols-5 gap-10 my-4">
                                {memberDetails.map((member, i) => <InfoCard classNames="!bg-white" key={i} showMenu={true} deleteAction={() => deleteMemeber(member.id)} member={member} editAction={() => editMemeber(member.id)}/>)}
                                <InfoCard classNames={'border-dashed border-4 !bg-white'}>
                                    <div onClick={() => setOpenModal(true)} className="p-8 h-full flex text-gray-600 flex-col justify-center items-center align-center hover:cursor-pointer">
                                        <RiAddCircleFill className="text-6xl" />
                                        <div className="font-bold w-full text-center"> Add Members </div>
                                    </div>
                                </InfoCard>
                            </div>
                            <Enroll />
                        </div>
                </>
            }
            <MemberForm openModal={openModal} setOpenModal={setOpenModal} editMember={member}  />
        </>
}

export default Students

