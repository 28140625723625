import { useDispatch, useSelector } from "react-redux"
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react"
import { eventsActions, authActions } from "../../../_store"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import { Spinner } from 'flowbite-react';
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import { useRef, useState } from "react"
import { BiCloudUpload } from 'react-icons/bi'

const EventsModal = (props) => {

    const dispatch = useDispatch()

    const { error: authError } = useSelector(state => state.auth)

    // form validation rules 
    const validationSchema = Yup.object().shape({
        event_name: Yup.string().required('Frist name is required'),
        event_desc: Yup.string().required('Last name is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const submitAction = async (data) => {
        console.log(data)
        const files = fileRef.current.files;
        const body = new FormData();
        body.append('file', files[0])
        body.append('event_name', data.event_name)
        body.append('event_desc', data.event_desc)
        body.append('event_type', data.event_type)
        body.append('sub_context', data.sub_context)
        body.append('from_date', data.from_date ? data.from_date.toISOString() : '')
        body.append('to_date', data.to_date ? data.to_date.toISOString() : '')
        console.log(body)
        const resp = await dispatch(eventsActions.createEvent(body))
        if (resp.meta.requestStatus === 'fulfilled') {
            dispatch(eventsActions.showCreateEventsModal(false))
        }
    }

    const eventType = watch("event_type", "SINGLE_DAY_EVENT");
    console.log("Event Type : " + eventType)



    const common_style = "border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 1);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];


    const fileRef = useRef()
    const [file, setFile] = useState(null)

    const handleChange = (event) => {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }

    // const uploadFile = async () => {
    //     const files = fileRef.current.files;

    //     if(!file) return
    //     const body = new FormData();
    //     body.append('file', files[0])
    //     const resp = await dispatch(utilityActions.uploadFile(body))
    //     if (resp.meta.requestStatus === 'fulfilled') {
    //         props.action(null)
    //     }
    // }

    return <>
        <Dialog open={true} handler={() => dispatch(eventsActions.showCreateEventsModal(false))} size="lg" popup dismiss={{ enabled: false }}>
                <DialogHeader>
                    <div className="flex flex-between py-4 px-6 w-full">
                            <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                                <div> Create Events </div>
                            </div>
                            <button onClick={() => dispatch(eventsActions.showCreateEventsModal(false))} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                    </div>
                </DialogHeader>
                <DialogBody className="h-[56rem] overflow-scroll w-[95%] m-auto">
                    <form className=" h-[56rem]" onSubmit={handleSubmit(submitAction)}>
                        <div className="flex flex-col">
                            <div className="px-6 space-y-4 md:space-y-6 sm:px-8">
                                    <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 ">Event name</label>
                                            <input type="text"  id="name" {...register('event_name')} className={common_style} placeholder="Event name" required="" />
                                            <p className="text-red-600 mt-2 text-xs">{errors.event_name?.message}</p>
                                        </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Event Descrption</label>
                                        <textarea {...register('event_desc')}  className={common_style} required rows={4} type="text" placeholder="Event Description"/>
                                        <p className="text-red-600 mt-2 text-xs">{errors.event_desc?.message}</p>
                                    </div>
                                    <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 ">Event Type</label>
                                            <select {...register('event_type')}  id="event_type" className={common_style} required >
                                                <option value={'SINGLE_DAY_EVENT'}> Single Day Event </option>
                                                <option value={'EXTENDED_EVENT'}> Extended Event </option>
                                                <option value={'INFORMATION'}> Information </option>
                                            </select>
                                            <p className="text-red-600 mt-2 text-xs">{errors.event_type?.message}</p>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        { eventType !== 'INFORMATION' &&
                                            <div>
                                                <label className="block mb-2 text-sm font-medium text-gray-900 ">{ eventType === 'SINGLE_DAY_EVENT' ? "Event On" : "From Date"}</label>
                                                <Controller
                                                    control={control}
                                                        name='from_date'
                                                        render={({ field }) => (
                                                        <DatePicker
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                                        {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                                    </select>
                                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                                        {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                                    </select>
                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                                </div>
                                                            )}
                                                            showIcon
                                                            icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                                            className='block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-gray-300 dark:focus:ring-gray-300 !p-2.5 text-sm rounded-lg' 
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText='MM/DD/YYYY'
                                                            onChange={(date) => field.onChange(date)}
                                                            selected={field.value}
                                                        />
                                                    )}
                                                />
                                                {/* <input {...register('from_date')} pattern="\d{4}-\d{2}-\d{2}" className={common_style} type="date"/> */}
                                                <p className="text-red-600 mt-2 text-xs">{errors.from_date?.message}</p>
                                            </div>
                                        }
                                        {eventType === 'EXTENDED_EVENT' &&
                                            <div>
                                                <label className="block mb-2 text-sm font-medium text-gray-900 ">To Date</label>
                                                <Controller
                                                    control={control}
                                                        name='to_date'
                                                        render={({ field }) => (
                                                        <DatePicker
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                                        {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                                    </select>
                                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                                        {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                                    </select>
                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                                </div>
                                                            )}
                                                            showIcon
                                                            icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                                            className='block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-gray-300 dark:focus:ring-gray-300 !p-2.5 text-sm rounded-lg' 
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText='MM/DD/YYYY'
                                                            onChange={(date) => field.onChange(date)}
                                                            selected={field.value}
                                                        />
                                                    )}
                                                />
                                                {/* <input {...register('to_date')} pattern="\d{4}-\d{2}-\d{2}" className={common_style} type="date"/> */}
                                                <p className="text-red-600 mt-2 text-xs">{errors.to_date?.message}</p>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Sub Context</label>
                                        <input type="text"  id="name" {...register('sub_context')} className={common_style} placeholder="eg - 10:00 AM to 11:00AM" required="" />
                                        <p className="text-red-600 mt-2 text-xs">{errors.sub_context?.message}</p>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Attachment</label>
                                        <div className="flex flex-col justify-center items-center border-dashed border-2 rounded bg-[#f6f7f8] hover:cursor-pointer text-sm" onClick={() => fileRef.current.click()}>
                                            <input type="file" ref={fileRef} className="hidden" onChange={handleChange} accept="image/*" />
                                            {file ? <div className="text-center">Selected: {file.name} <br/> or..</div>: <></>}
                                            <BiCloudUpload className="h-8 w-12 m-0"/>
                                            <div>Click to open</div>
                                        </div>
                                    </div>
                                    {
                                        authError && authError.message &&
                                        <div className="mx-auto w-[80%] p-2 border-red-500 border border-l-4 flex items-center justify-center"> 
                                        <svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                        
                                            <p className="text-xs text-center text-black-600 w-full">
                                                {authError.message}
                                            </p>
                                        </div>
                                    }
                                    <button  disabled={isSubmitting} className="w-full text-white bg-black font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        { isSubmitting ? <Spinner /> :  "Create Event" }
                                    </button>

                            </div>
                        </div>
                    </form>
                </DialogBody>
                <DialogFooter className="px-20 pb-6 justify-stretch	">
                </DialogFooter>
        </Dialog>
    </>
}

export default EventsModal