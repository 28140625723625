import { Radio, Label, Modal, TextInput, Select, Spinner } from 'flowbite-react';
import Ripples from 'react-ripples'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { memberActions } from '../../../_store';
import { useEffect, useState } from 'react';
import { plans } from '../../../_helpers';
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";

const MemberForm = (props) => {
    
    const dispatch = useDispatch();

    const { error: memberError } = useSelector(state => state.member)
    
    const [selectedPlan, setSelectedPlan] = useState(undefined)

    const [memberDetail, setMemberDetail] = useState(undefined)

    useEffect(() => {
        if(props.editMember) {
            setMemberDetail(props.editMember)
        }
    }, [props.editMember])

    // form validation rules 
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        relationship: Yup.string().required('Relationship is required'),
        plan: Yup.string().required('Please select a plan'),
        dob: Yup.string().required('Please select date of birth')
    });



    const formOptions = { resolver: yupResolver(validationSchema) };


    const { register, handleSubmit, formState, reset, setValue, control, clearErrors, watch } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if(memberDetail?.plan?.name) {
            console.log("plan");
            clearErrors('plan');
        }
        if(memberDetail?.dob) {
            console.log("dob");
            clearErrors('dob');
        }
        reset(defaultValue)
        setSelectedPlan(memberDetail?.plan?.name)
        
    }, [memberDetail, clearErrors]);

    const closeModal = () => {
        reset({})
        props.setOpenModal(undefined)
        setSelectedPlan(undefined)
        setMemberDetail(undefined)
    }

    const onPlanSelect = (value) => {
        setValue('plan', value)
        setSelectedPlan(value)
    }

    const defaultValue = {
        first_name: memberDetail && memberDetail.name ? memberDetail.name.split('-')[0] : null,
        middle_initial: memberDetail && memberDetail.name ? memberDetail.name.split('-')[1] : null,
        last_name: memberDetail && memberDetail.name ? memberDetail.name.split('-')[2] : null,
        gender: memberDetail ? memberDetail.gender : null,
        dob: memberDetail && memberDetail.dob ? new Date(memberDetail.dob) : null,
        relationship: memberDetail && memberDetail.relationship ? memberDetail.relationship : null,
        plan: memberDetail && memberDetail.plan ? memberDetail.plan.name : null
    }
        
    

    const onSubmit = async (data) => {

        if(props.editMember) {
            data.id = memberDetail.id
            data.name = data.first_name + '-' + (data.middle_initial ?? '') + '-' + (data.last_name ?? '')
            const resp = await dispatch(memberActions.editMember(data));
            console.log("response",resp.meta.requestStatus)
            if (resp.meta.requestStatus === 'fulfilled') {
                closeModal()
            }
        } else {
            data.name = data.first_name + '-' + (data.middle_initial ?? '') + '-' + (data.last_name ?? '')
            const resp = await dispatch(memberActions.addMember(data));
            console.log(resp.meta.requestStatus)
            if (resp.meta.requestStatus === 'fulfilled') {
                closeModal()
            }
        }

        return 
    }

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 1);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return <>
        <Modal show={props.openModal} size="2xl" dismissible popup onClose={() => closeModal()}>
            <Modal.Header>
                <div className="text-2xl px-2 py-2 font-medium text-gray-900 dark:text-white">{memberDetail ? "Edit Student" : "Add Student"}</div> 
            </Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <form>
                        <div className='grid md:grid-cols-2 gap-4'>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="first_name" value="First Name" />
                                </div>
                                <TextInput {...register('first_name')} className='focus:border-gray-300' id="s_firstname" placeholder="First name" required />
                                <p className="text-xs text-red-600 mt-2">{errors.first_name?.message}</p>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="last_name" value="Last Name" />
                                </div>
                                <TextInput {...register('last_name')} className='focus:border-gray-300' id="s_lastname" placeholder="Last name" required />
                                <p className="text-xs text-red-600 mt-2">{errors.last_name?.message}</p>
                            </div>
                            <div className='hidden'>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="middle_initial" value="Initial" />
                                </div>
                                <TextInput {...register('middle_initial')} className='focus:border-gray-300' id="s_middleinitial" placeholder="-" required />
                                <p className="text-xs text-red-600 mt-2">{errors.middle_initial?.message}</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            {/* <div className='col-span-2'>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="name" value="Student Name" />
                                </div>
                                <TextInput {...register('name')} className='focus:border-gray-300' id="s_username" placeholder="Member name" required />
                                <p className="text-xs text-red-600 mt-2">{errors.name?.message}</p>
                            </div> */}
                                
                            <div className='col-span-2'>
                                    <fieldset className="flex max-w-md flex-col gap-4" id="radio" >
                                        <Label className='font-semibold' htmlFor="name" value="Select student gender" />
                                        <div className="flex items-center gap-2">
                                            <Radio {...register("gender")} className='focus:ring-blue-600 text-blue-600' defaultChecked id="male" name="gender" value="Male" />
                                            <Label htmlFor="united-state"> Male </Label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Radio {...register("gender")} className='focus:ring-blue-600 text-blue-600' id="female" name="gender" value="Female" />
                                            <Label htmlFor="germany"> Female </Label>
                                        </div>
                                    </fieldset>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="dob" value="Date of birth" />
                                </div>
                                <Controller
                                        control={control}
                                        name='dob'
                                        render={({ field }) => (
                                        <DatePicker
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                    </select>
                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                        {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                    </select>
                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                </div>
                                            )}
                                            showIcon
                                            icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                            className='block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-gray-300 dark:focus:ring-gray-300 !p-2.5 text-sm rounded-lg' 
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText='MM/DD/YYYY'
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                        />
                                    )}
                                />
                                <p className="text-xs text-red-600 mt-2">{errors.dob?.message}</p>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='font-semibold' htmlFor="relationship" value="Relation" />
                                </div>
                                <Select {...register("relationship")} id="relationship" required>
                                    <option value={""}> Choose relation </option>
                                    <option> Daughter </option>
                                    <option> Son </option>
                                    <option> Spouse </option>
                                    <option> Myself </option>
                                    <option> Other </option>
                                </Select>
                                <p className="text-xs text-red-600 mt-2">{errors.relationship?.message}</p>
                            </div>
                            {
                                (!memberDetail || !memberDetail.programEnrollment) && 
                                    <div className='col-span-2'>
                                        <div className="mb-2 block">
                                            <Label className='font-semibold' htmlFor="relationship" value="Choose a program" />
                                        </div>
                                        <div className='flex space-x-4'>
                                            {plans.map((p, i) => 
                                                <Ripples key={i}>
                                                    <div {...register("plan")} value={p.value} className={`${selectedPlan === p?.value ? 'bg-blue-700 text-white' : 'bg-gray-100 text-black'} flex items-center justify-center shadow font-medium hover:cursor-pointer w-40 text-center p-4 rounded`} onClick={() => onPlanSelect(p.value)}>
                                                        {p.display}
                                                    </div>
                                                </Ripples>
                                            )}
                                        </div>
                                        <p className="text-xs text-red-600 mt-2">{errors.plan?.message}</p>
                                    </div>
                            }

                            {
                                memberError && memberError.message &&
                                <div className="mx-auto col-span-2 p-2 border-red-500 border border-l-4 flex items-center justify-center"> 
                                <svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                
                                    <p className="text-xs text-center text-black-600 w-full">
                                        {memberError.message}
                                    </p>
                                </div>
                            }

                            <div className="mt-8 col-span-2 w-full flex justify-center">
                                <Ripples>
                                    <button onClick={handleSubmit(onSubmit)} disabled={isSubmitting} className="text-white rounded text-sm bg-gray-800 px-20 py-3">
                                        { isSubmitting ?  <Spinner /> :  "Save" }
                                    </button>
                                </Ripples>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
      </>
}

export default MemberForm