import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { userReducer } from './user.slice';
import { dashboardReducer } from './dashboard.slice';
import { membersReducer } from './members.slice';
import { memberReducer } from './member.slice';
import { utilityReducer } from './utility.slice';
import { ordersReducer } from './orders.slice.js';
import { paymentReducer } from './payments.slice.js';
import { eventsReducer } from './events.slice.js';

export * from './auth.slice';
export * from './user.slice';
export * from './dashboard.slice';
export * from './members.slice';
export * from './member.slice';
export * from './utility.slice';
export * from './orders.slice.js';
export * from './payments.slice.js';
export * from './events.slice.js';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        utility: utilityReducer,
        dashboard: dashboardReducer,
        members: membersReducer,
        member: memberReducer,
        orders: ordersReducer,
        payment: paymentReducer,
        events: eventsReducer
    },
});