import Ripples from 'react-ripples'
import { BsThreeDotsVertical } from "react-icons/bs"
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import DeleteAlert from '../alert-box/DeleteAlert';
import { useState } from 'react';
import { getPlanStatus, utilities } from '../../_helpers';
import { Badge } from 'flowbite-react';
import Avatar, { ConfigProvider } from 'react-avatar';
import { Checkbox } from "@material-tailwind/react";

const InfoCard = (props) => {
    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return  <ConfigProvider colors={['#A62A21', '#7e3794', '#0B51C1', '#3A6024', '#A81563', '#B3003C']}>
                <Ripples className='rounded-lg relative'>
                    <div className={`border ${props.height ? props.height : 'h-64'} hover:cursor-pointer rounded-lg shadow text-gray-600 p-4 w-full ${props.classNames ? props.classNames : ''}`} style={{background: "#f7fbff"}}>
                                {props.member ? <div className='h-full'>
                                    <div className={`${props.showMenu ? 'block' : 'hidden'}`}>
                                        {/* {props.member.programEnrollment && 
                                            <div className={`absolute -top-0 left-0 text-2xl`}>
                                                <BsBookmarkCheckFill className='text-green-500'/>
                                            </div>
                                        } */}
                                        <div className={`absolute top-3 right-2 p-1 rounded-xl hover:bg-gray-200`}>
                                            <Menu menuButton={<MenuButton><BsThreeDotsVertical/></MenuButton>}>
                                                <MenuItem onClick={() => props.editAction(props.member.id)} >Edit</MenuItem>
                                                {!props.member.programEnrollment && <MenuItem onClick={() => setOpenDeleteModal(!openDeleteModal)}>Delete</MenuItem> }
                                            </Menu>            
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center align-center">
                                        <Avatar name={props.member.name.replace(/-/g, ' ')} round={true} />
                                        <div className="my-4 text-xl font-semibold text-center">{utilities.capitalize(props.member.name.replace(/-/g, ' '))}</div>
                                        <div className="grid  gap-1 text-center">
                                            <Badge color={`${getPlanStatus(props.member.programEnrollment).color}`} className="text-center"> {getPlanStatus(props.member.programEnrollment).display} </Badge>
                                        </div>
                                        {props.checkAction && 
                                            <div>
                                                    <Checkbox  onChange={(e) => props.checkAction(e)} value={props.member.id} color="blue" className='focus:ring-0 or focus:ring-transparent'/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                    <div className='h-full'>{props.children}</div>
                                }
                    </div>
                    <DeleteAlert openModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
                                confirmAction={() => props.deleteAction()}/>
                </Ripples>
        </ConfigProvider>
}

export default InfoCard