import { useDispatch, useSelector } from "react-redux"
import { ordersActions } from "../../../_store"
import { useEffect, useState } from "react"
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react"
import EnrollmentSummary from "../../../components/enrollment-summary/EnrollmentSummary"
import { Label } from "flowbite-react"
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";

const Approvals = (props) => {
    const dispatch = useDispatch()
    const APPROVAL = 'APPROVAL'
    const REJECTED = 'REJECTED'
    const { enrollments: enrollments } =  useSelector(state => state.orders)

    const [alertType, setAlertType] = useState(APPROVAL)
    const [showConfirm, setShowConfirm] = useState(false)
    const [schedulePayload, setSchedulePayload] = useState({})

    useEffect(() => {
        if(props.selectedUserId) {
            const schedulePayload = {
                action: "pendings",
                user_id: props.selectedUserId
            }
            dispatch(ordersActions.getEnrollments(schedulePayload))
        }
    }, [props.selectedUserId])

    const reviewInitiated = (id, type) => {
        setShowConfirm(true)
        setAlertType(type)
        setSchedulePayload({
            ...schedulePayload,
            order_id: id
        })
    }

    const updateFormValue = (event) => {
        setSchedulePayload({
            ...schedulePayload,
            [event.target.id]: event.target.value
        })
    }

    const updateDateValue = (date, id) => {
        setSchedulePayload({
            ...schedulePayload,
            [id]: date
        })
    }

    const closeForm = () => {
        setShowConfirm(false)
        props.action(null)
    }

    const confirmOrder = async () => {
        const payload = {
            ...schedulePayload,
            user_id: props.selectedUserId,
            action: "approved",
            no_of_payments: parseInt(schedulePayload.no_of_payments)
        }
        console.log(payload)
        const resp = await dispatch(ordersActions.reviewEnrollments(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            closeForm()
        }
    }

    const rejectOrder = async () => {
        const payload = {
            ...schedulePayload,
            user_id: props.selectedUserId,
            action: "rejected"
        }
        console.log(payload)
        const resp = await dispatch(ordersActions.reviewEnrollments(payload))
        if (resp.meta.requestStatus === 'fulfilled') {
            closeForm()
        }
    }

        
    const enabled_theme = 'w-full bg-gray-50 bg-white border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg'

    const ALERT = {
        APPROVAL: {
            header: <div className="flex flex-between py-4 px-2 w-full">
                            <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                                <div> Please provide the details to schedule </div>
                            </div>
                            <button onClick={() => setShowConfirm(!showConfirm)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                        </div>,
            body: <form>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <div className="block">
                                    <Label htmlFor="small" value="Number of payments" color="text-red"/>
                                </div>
                                <input id="no_of_payments" type="number" min={1} max={12} maxLength={2} placeholder="0" className={enabled_theme} onChange={(e) => updateFormValue(e)}/>
                            </div>
                            <div>
                                <div className="block">
                                    <Label htmlFor="small" value="Start date" color="text-red"/>
                                </div>
                                <DatePicker
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                            </select>
                                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                            </select>
                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                        </div>
                                    )}
                                    showIcon
                                    icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                    className='block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-gray-300 dark:focus:ring-gray-300 !p-2.5 text-sm rounded-lg' 
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText='MM/DD/YYYY'
                                    onChange={(date) => updateDateValue(date, 'enrollment_date')}
                                    selected={schedulePayload.enrollment_date}
                                />
                                {/* <input id="enrollment_date" pattern="\d{4}-\d{2}-\d{2}" type="date" className={enabled_theme} onChange={(e) => updateFormValue(e)}/> */}
                            </div>
                        </div>
                    </form>,
            footer: <Button onClick={() => confirmOrder()}> Schedule</Button>
        },
        REJECTED: {
            header: <div className="flex flex-between px-2 w-full">
                        <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                            <div> Are you sure ? </div>
                        </div>
                        <button onClick={() => setShowConfirm(!showConfirm)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                </div>,
            body: <div> You won't be able to revert this</div>,
            footer: <Button size="sm" variant="outlined" color="red" onClick={() => rejectOrder()}> Reject</Button>
        }
    }
    

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 2);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return <>
        <Dialog open={true} handler={() => props.action(null)} size="xl" popup dismiss={{ enabled: false }}>
                <DialogHeader>
                    <div className="flex flex-between py-4 px-6 w-full">
                            <div className="text-xl font-semibold text-gray-900 dark:text-white"> 
                                <div> Review enrollments </div>
                            </div>
                            <button onClick={() => props.action(null)} aria-label="Close" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                    </div>
                </DialogHeader>
                <DialogBody className="p-4 h-[32rem] bg-[#f6f7f8] overflow-scroll w-[95%] m-auto  p-6">
                    {enrollments ?
                            enrollments.map(order => <div className="mt-8">
                                    <EnrollmentSummary classNames={'text-sm'} summary={JSON.parse(order.summary)} members={order.enrolled_members} >
                                        <div className="mt-8  gap-4 flex justify-center">
                                            <Button variant="outlined" onClick={() => reviewInitiated(order.id, REJECTED)}> Reject Enrollment</Button>
                                            <Button color="" onClick={() => reviewInitiated(order.id, APPROVAL)}> Confirm Enrollment</Button>
                                        </div>
                                    </EnrollmentSummary>
                                </div>)
                        : <></>}
                </DialogBody>
                <DialogFooter className="px-20 pb-6 justify-stretch	">
                </DialogFooter>
        </Dialog>
        <Dialog size="xs" open={showConfirm} handler={() => setShowConfirm(!showConfirm)}  className="shadow-none z-[1000000]" dismiss={{ enabled: false }}>
            <DialogHeader>
                {ALERT[alertType].header}
            </DialogHeader>
            <DialogBody>
                {ALERT[alertType].body}
            </DialogBody>
            <DialogFooter>
                {ALERT[alertType].footer}
            </DialogFooter>
        </Dialog>
    </>
}

export default Approvals