import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        user: {},
        guardianUser: {},
        users: { data: [], loading: false},
        feeds: { data: [], loading: false},
    }
}
function createReducers() {
    return {
        resetUser
    };

    function resetUser(state) {
        console.log(state)
        Object.assign(state, createInitialState())
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;
    const baseUsersUrl = `${process.env.REACT_APP_API_URL}/users`;

    return {
        getUser: getUser(),
        getUserById: getUserById(),
        getAllUsers: getUsers(),
        updateUser: updateUser(),
        getFeeds: getFeeds()
    };

    function getUser() {
        return createAsyncThunk(
            `${name}/getUser`,
            async (value, { dispatch }) => await fetchWrapper.get(`${baseUrl}/me`, null, null, dispatch)
        );
    }

    function getUserById() {
        return createAsyncThunk(
            `${name}/getUserById`,
            async (data, { dispatch }) => await fetchWrapper.get(`${baseUrl}/${data.user_id}`, data, null, dispatch)
        );
    }

    function getUsers() {
        return createAsyncThunk(
            `${name}/getUsers`,
            async (value, { dispatch }) => await fetchWrapper.get(baseUsersUrl, null, null, dispatch)
        );
    }

    function updateUser() {
        return createAsyncThunk(
            `${name}/updateUser`,
            async ({ data, action}, { dispatch }) => {
                if(!action) {
                    action = () => dispatch(userActions.getUser())
                }
                console.log(data)
                await fetchWrapper.put(`${baseUrl}/${data.id}`, data, action, dispatch)
            }
        );
    }

    function getFeeds() {
        return createAsyncThunk(
            `${name}/getFeeds`,
            async (value, { dispatch }) => await fetchWrapper.get(`${baseUrl}/feeds`, null, null, dispatch)
        );
    }

}

function createExtraReducers() {
    return {
        ...getUser(),
        ...getUserById(),
        ...getAllUsers(),
        ...updateUser(),
        ...getFeeds()
    };

    function getUser() {
        var { pending, fulfilled, rejected } = extraActions.getUser;
        return {
            [pending]: (state) => {
                state.user = { loading: true };
            },
            [fulfilled]: (state, action) => {
                const userState = {
                    ...state.user,
                    ...action.payload,
                    roles: action.payload.roles.map(role => role.name)
                }

                state.user = userState;
            },
            [rejected]: (state, action) => {
                state.user = { error: action.error };
            }
        };
    }

    function getUserById() {
        var { pending, fulfilled, rejected } = extraActions.getUserById;
        return {
            [pending]: (state) => {
                state.guardianUser = { loading: true };
            },
            [fulfilled]: (state, action) => {
                const userState = {
                    ...state.guardianUser,
                    ...action.payload
                }

                state.guardianUser = userState;
            },
            [rejected]: (state, action) => {
                state.guardianUser = { error: action.error };
            }
        };
    }

    function getAllUsers() {
        var { pending, fulfilled, rejected } = extraActions.getAllUsers;
        return {
            [pending]: (state) => {
                state.users = { ...state.users, loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = { ...state.users, data: action.payload, loading: false };
            },
            [rejected]: (state, action) => {
                state.users = { ...state.users, error: action.error, loading: false };
            }
        };
    }

    function updateUser() {
        var { pending, fulfilled, rejected } = extraActions.updateUser;
        return {
            [pending]: (state) => {
                state.user = { ...state.user, loading: true };
            },
            [fulfilled]: (state, action) => {
                // state.user = action.payload;
            },
            [rejected]: (state, action) => {
                state.user = { error: action.error };
            }
        };
    }

    function getFeeds() {
        var { pending, fulfilled, rejected } = extraActions.getFeeds;
        return {
            [pending]: (state) => {
                state.feeds = { ...state.feeds, loading: true };
            },
            [fulfilled]: (state, action) => {
                state.feeds = { ...state.feeds, data: action.payload, loading: false };
            },
            [rejected]: (state, action) => {
                state.feeds = { ...state.feeds, error: action.error, loading: false };
            }
        };
    }
}
