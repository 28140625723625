import { AiFillHome, AiOutlineLogout } from 'react-icons/ai';
import { FaUserGroup, FaMoneyBill, FaUsersLine} from 'react-icons/fa6';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'
import { VscAccount } from 'react-icons/vsc'
import { GrSchedule } from "react-icons/gr";
import { RiExchangeDollarFill } from "react-icons/ri";
import { MdOutlineReceiptLong } from "react-icons/md";
import { GrSchedules } from "react-icons/gr";
import { authActions, userActions } from '../../_store';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../img/logo.png';
import { useState } from 'react';
import menu from '../../img/menu.png';
import close from '../../img/close.png';

const SideNav = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { activePage: activePage } =  useSelector(state => state.utility)
    const { user } =  useSelector(state => state.user)
    const [toggle, setToggle] = useState(false);

    const logout = () => {
        dispatch(authActions.logout())
        dispatch(userActions.resetUser())
    }

    const items = [
        {
            "icon": <AiFillHome className='text-gray-700 text-xl' />,
            "value": "Home",
            "href": "/",
            "allowed": ["MEMBER", "ADMIN"]
        }, {
            "icon": <FaUserGroup className='text-gray-700 text-xl' />,
            "value": "Manage Students",
            "href": "/members",
            "allowed": ["MEMBER", "ADMIN"]
        },
        {
            "icon": <FaUsersLine className='text-gray-700 text-xl' />,
            "value": "Manage Families",
            "href": "/users",
            "allowed": ["ADMIN"]
        },
        {
            "icon": <GrSchedules className='text-gray-700 text-xl' />,
            "value": "Manage Events",
            "href": "/events",
            "allowed": ["ADMIN"]
        },
        {
            "icon": <RiExchangeDollarFill className='text-gray-700 text-xl' />,
            "value": "Manage Subscription",
            "href": "/subscription",
            "allowed": ["ADMIN"]
        },
        {
            "icon": <GrSchedule className='text-gray-700 text-xl' />,
            "value": "Schedule",
            "href": "/schedule",
            "allowed": ["MEMBER", "ADMIN"]
        },
         {
            "icon": <FaMoneyBill className='text-gray-700 text-xl' />,
            "value": "Payments",
            "href": "/payments",
            "allowed": ["MEMBER"]
        },
        {
            "icon": <MdOutlineReceiptLong className='text-gray-700 text-xl' />,
            "value": "Billings",
            "href": "/billings",
            "allowed": ["MEMBER"]
        },
         {
            "icon": <VscAccount className='text-gray-700 text-xl' />,
            "value": "My Account",
            "href": "/accounts",
            "allowed": ["MEMBER"]
        },
        {
            "icon": <FiSettings className='text-gray-700 text-xl' />,
            "value": "Settings",
            "href": "/settings",
            "allowed": ["ADMIN"]
        },
    ]

    const updateNavigate = async (href) => {
        navigate(href)
        setToggle(false);
    }

    return <div>
        <div className='border w-[20.5rem] hidden sm:block relative h-screen'>
            <div className='my-4 border-b pb-2'>
                <img className='w-48 sm:w-72' src={Logo} />
            </div>
            <div className={`mt-6`}>
                {user.roles && items.filter(item => item.allowed.some(a => user.roles.includes(a)))
                    .map((item, i) => 
                <div key={i} onClick={() => updateNavigate(item.href)} className={`${activePage === item.value ? 'border-r-4 border-black bg-gray-200' : ''} flex items-center p-4 hover:bg-gray-200 hover:cursor-pointer`}>
                    <div className='w-8'>
                        {item.icon}
                    </div>
                    <div>
                        {item.value}
                    </div>
                </div>)}
            </div>
            <div className='absolute bottom-6 w-full'>
                <div onClick={() => logout()} className='flex items-center justify-center m-2 p-4 pr-8 bg-gray-200 hover:cursor-pointer rounded-lg'>
                    <div className='w-5'>
                        <AiOutlineLogout />
                    </div>
                    <div> Sign out </div>
                </div>
            </div>
        </div>
        <div className="block bg-primary w-full sm:hidden">
            <div className={`w-full items-center relative`}>
                <div>
                    <div className={`flex justify-between`}>
                        {/* Mobile Navigation */}
                        <div className='border-b pb-2'>
                            <img className='w-48 sm:w-72' src={Logo} />
                        </div>
                            <div>
                                <img
                                src={toggle ? close : menu}
                                alt="menu"
                                className="w-[28px] h-[28px] object-contain right-[20px] top-[25px]"
                                onClick={() => setToggle(!toggle)}
                                />
                        </div>
                    </div>
                </div>
                <div className="w-full h-full sm:hidden">
                    {/* Sidebar */}
                    <div className= {`${!toggle ? "hidden" : "h-screen bg-[#f6f7f8]"}`} >
                        {
                            user.roles && items.filter(item => item.allowed.some(a => user.roles.includes(a)))
                                .map((item, i) => 
                                        <div key={i} onClick={() => updateNavigate(item.href)} className={`${activePage === item.value ? 'border-r-4 border-black bg-gray-200' : ''} flex items-center p-4 hover:bg-gray-200 hover:cursor-pointer`}>
                                            <div className='w-8'>
                                                {item.icon}
                                            </div>
                                            <div>
                                                {item.value}
                                            </div>
                                        </div>)
                        }
                        <div className='absolute bottom-6 w-full'>
                            <div onClick={() => logout()} className='flex items-center justify-center m-2 p-4 pr-8 bg-gray-200 hover:cursor-pointer rounded-lg'>
                                <div className='w-5'>
                                    <AiOutlineLogout />
                                </div>
                                <div> Sign out </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
  </div>
}

export default SideNav