import { Spinner } from "flowbite-react"

const SecurityCard = (props) => {
    return <div className={`w-full bg-white shadow p-4 my-4 `}>
        <div>
            <div className={`grid h-12 ${props.showEditable ? 'grid-cols-2' : ''}`}>
                    <div className="flex items-center text-xl text-gray-800 font-bold">
                            {props.headerIcon}
                            <div className="pl-2">{props.title}</div>
                            {props.action ? <div className="flex ml-auto"> {props.action}</div> : <div/>}
                    </div>
                    <div>
                        <div className="flex justify-end">
                            {props.showEditable && (props.editable ?
                                <>
                                    <div onClick={() => props.setEditable()} className="px-4 py-2 mr-4 bg-gray-100 shadow text-black rounded hover:cursor-pointer">
                                        Cancel
                                    </div>
                                    <div onClick={() => props.saveAction()} className="px-4 py-2 w-24 text-center bg-gray-900 text-white rounded hover:cursor-pointer">
                                        {props.isSubmitting ? <Spinner /> : 'Save'}
                                    </div>  
                                </>:
                                <div onClick={() => props.setEditable()} className="px-4 py-2 w-24 text-center bg-gray-900 text-white rounded hover:cursor-pointer">
                                    Edit
                                </div>)
                            }
                        </div>
                    </div>
            </div>
            <div className={`py-3 ${props.classNames ? props.classNames : ''}`}>
                {props.children}
            </div>
        </div>
    </div>
}

export default SecurityCard