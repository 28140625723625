import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import { userActions } from './user.slice';

// create slice

const name = 'payment';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const paymentActions = { ...slice.actions, ...extraActions };
export const paymentReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        paymentMethods: [],
        error: null,
        paymentTransactions: {},
        plansVersions: []
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/payment`;

    return {
        getPaymentMethods: getPaymentMethods(),
        addPaymentMethods: addPaymentMethods(),
        setDefaultPaymentMethods: setDefaultPaymentMethods(),
        getPaymentTransactions: getPaymentTransactions(),
        getPlanVersions: getPlanVersions(),
        addPlan: addPlan(),
        activatePlan: activatePlan()
    };    

    function getPaymentMethods() {
        return createAsyncThunk(
            `${name}/getPaymentMethods`,
            async (value, { dispatch }) => await fetchWrapper.get(baseUrl + '/methods', null, null, dispatch)
        );
    }

    function addPaymentMethods() {
        return createAsyncThunk(
            `${name}/addPaymentMethods`,
            async (value, { dispatch }) => await fetchWrapper.post(baseUrl + '/methods', value, () => dispatch(paymentActions.getPaymentMethods()), dispatch)
        );
    }

    function setDefaultPaymentMethods() {
        return createAsyncThunk(
            `${name}/setDefaultPaymentMethods`,
            async (value, { dispatch }) => await fetchWrapper.put(baseUrl + '/method/' + value, null, () => dispatch(paymentActions.getPaymentMethods()), dispatch)
        );
    }

    function getPaymentTransactions() {
        return createAsyncThunk(
            `${name}/getPaymentTransactions`,
            async (value, { dispatch }) => await fetchWrapper.get(baseUrl + '/transactions', null, null, dispatch)
        );
    }

    function getPlanVersions() {
        return createAsyncThunk(
            `${name}/getPlanVersions`,
            async (value, { dispatch }) => await fetchWrapper.get(baseUrl + '/plans', null, null, dispatch)
        );
    }

    function addPlan() {
        return createAsyncThunk(
            `${name}/addPlan`,
            async (value, { dispatch }) => await fetchWrapper.post(baseUrl + '/plans', value, () => dispatch(paymentActions.getPlanVersions()), dispatch)
        );
    }

    function activatePlan() {
        return createAsyncThunk(
            `${name}/activatePlan`,
            async (value, { dispatch }) => await fetchWrapper.put(baseUrl + '/plans', value,() => dispatch(paymentActions.getPlanVersions()), dispatch)
        );
    }
}

function createExtraReducers() {
    return {
        ...getpayment(),
        ...addPayment(),
        ...getPaymentTransactions(),
        ...getPlanVersions()
    };

    function getpayment() {
        var { pending, fulfilled, rejected } = extraActions.getPaymentMethods;
        return {
            [pending]: (state) => {
                state.payment = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.paymentMethods = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function addPayment() {
        var { pending, fulfilled, rejected } = extraActions.addPaymentMethods;
        return {
            [pending]: (state) => {
                state.payment = { loading: true };
            },
            [fulfilled]: (state, action) => {
                console.log(action)
                console.log("Successuful")
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function getPaymentTransactions() {
        var { pending, fulfilled, rejected } = extraActions.getPaymentTransactions;
        return {
            [pending]: (state) => {
                state.payment = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.paymentTransactions = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    
    function getPlanVersions() {
        var { pending, fulfilled, rejected } = extraActions.getPlanVersions;
        return {
            [pending]: (state) => {
                state.payment = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.plansVersions = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

}
