import { utilityActions } from '../../_store';
import { useDispatch } from 'react-redux';
import { Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { CiWarning } from "react-icons/ci";

const SnackBar = (props) => {
	const dispatch = useDispatch();

	const handleDismiss = () => {
		// When the toast is dismissed, set showToast to false
		dispatch(utilityActions.toast({}))
		dispatch(utilityActions.showToast(false))
	}

	return 	<div className="w-full relative z-[99999]">
				<div className="absolute right-0 top-[60px]">
					
				{
					props.toast && props.showToast &&
						<Modal className='z-[1000000]' show={true} size="md" popup onClose={() => handleDismiss()}>
							<Modal.Header />
							<Modal.Body>
								<div className="text-center">
									{props.toast.type === 'ERROR' && <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-500" /> }
									{props.toast.type === 'WARN' && <CiWarning className="mx-auto mb-4 h-14 w-14 text-amber-600" /> }
									<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
										{props.toast.message}
									</h3>
								</div>
							</Modal.Body>
						</Modal>
				}
				</div>
			</div>
}

export default SnackBar;