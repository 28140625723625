import { useDispatch, useSelector } from "react-redux"
import SectionCard from "../../../components/section-card/SectionCard"
import { AiOutlineBank } from "react-icons/ai"
import { useEffect, useState } from "react"
import { paymentActions } from "../../../_store"
import TileCard from "../../../components/title-card/TileCard"
import Cheque from "../../../img/cheque-2.svg"
import PaymentModal from "../../../components/payment-modal/PaymentModal"
import Visa from "../../../img/visa.svg";
import Master from "../../../img/master-card.svg";
import DefaultCard from "../../../img/default-card.svg";
import { AiFillCheckCircle } from "react-icons/ai";


const PaymentMethods = (props) => {
    const { paymentMethods: methods } = useSelector(state => state.payment)
    const { user: user } = useSelector(state => state.user)
    const [selected, setSelected] = useState(null)
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(paymentActions.getPaymentMethods())
    }, [])

    useEffect(() => {
        if(props.onSelect) {
            props.onSelect(methods && methods.data && methods.data.length > 0)
        }
    }, [methods])
    
    const onSelect = (key) => {
        setSelected(key)
    }

    const setDefaultPaymentMethods = (key) => {
        console.log(key)
        dispatch(paymentActions.setDefaultPaymentMethods(key))
    }

    const cardIcons = {
        'Visa': <img src={Visa} className="w-[5rem]" />,
        'Master': <img src={Master} className="w-[5rem]" />,
        'default': <img src={DefaultCard} className="w-[5rem]" />,
    }

    const methodMapping = {
        "cc": {            
            "icon": (data) => cardIcons[data.card_type] ? cardIcons[data.card_type] : cardIcons['default'],
            "title": "Credit Card",
            "header": (data) => `${data.card_type} card ending in ${data.ccnum4last}`,
            "caption": (data) => `Expiry date ${data.expires.substring(0, 2)}\/${data.expires.substring(2)}`
        },
        "check": {
            "icon": (data) => <img src={Cheque} className="w-[5rem]" />,
            "title": "Check",
            "header": (data) => `Check Account number ending in ${data.account_number}`,
            "caption": (data) => `Routing number ${data.routing_number}`
        }
    }

    const addButton = () => {
        return <div onClick={() => setOpen(true)} className="px-4 py-2 text-center text-xs text-gray-500 border rounded-2xl rounded hover:cursor-pointer hover:bg-gray-50"> 
                    Add +
                </div>
    }
    
    return <SectionCard 
                classNames="overflow-scroll	max-h-80"
                showEditable={false}
                headerIcon={<AiOutlineBank className="text-blue-800"/>} 
                title={"Payment Methods"} 
                action={addButton()}
                >
                <PaymentModal open={open} setOpen={setOpen}/>
                <div className="grid grid-cols-1 gap-3">
                    {methods && methods.data && methods.data.map(method => 
                        <TileCard classNames={`!h-fit !bg-white border !shadow-none ${methods.usaepayPaymentMethodId && methods.usaepayPaymentMethodId === method.key ? '!border-gray-700 !border-2 !shadow': ''}`}>
                            <div className="flex h-full">
                                <div className="flex items-center mx-4">
                                    <div>{methodMapping[method.pay_type].icon(method)}</div>
                                </div>
                                <div className="col-span-2 py-4 h-full flex flex-col">
                                    <div className="font-semibold">
                                        <div className="text-sm text-gray-500 font-normal">
                                            <div className="text-black py-1">{methodMapping[method.pay_type].header(method)}</div>
                                            <div>{methodMapping[method.pay_type].caption(method)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center ml-auto mr-8">
                                    {
                                    methods.usaepayPaymentMethodId && methods.usaepayPaymentMethodId === method.key  ? 
                                        <div className="flex items-center text-xs"> <AiFillCheckCircle className="text-green-500 text-lg"/> <span className="hidden sm:block">Primary</span> </div>
                                        :
                                        <button className="text-blue-500 text-xs hover:underline" onClick={() => setDefaultPaymentMethods(method.key)}>
                                            Set default
                                        </button>
                                    }
                                </div>
                            </div>
                        </TileCard>
                    )}
                    {!methods ||  !methods.data || methods.data.length === 0 ?
                        <div className="flex h-20 justify-center items-center text-gray-500">
                            No payments methods found
                        </div> :
                        <></>
                    }
            
                </div>
        </SectionCard>
}

export default PaymentMethods