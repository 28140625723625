import { Button } from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TopNav from "../../components/topnav/TopNav"
import { eventsActions } from "../../_store"
import EventsModal from "./sections/EventsModal"
import EventsViews from "./sections/EventsView"


const Events = (props) => {
    
    const dispatch = useDispatch()
    const eventsState =  useSelector(state => state.events)

    console.log(eventsState)

    useEffect( 
        () => () => dispatch(eventsActions.showCreateEventsModal(false))
    , [] );

    return <>
        <TopNav 
            actions={
                <div>
                    <Button  onClick={() => dispatch(eventsActions.showCreateEventsModal(true))} className="">
                        Create Events
                    </Button>
                </div>
            }/>
        <div className="w-[75%] m-auto my-20">
            <EventsViews />
        </div>
        {eventsState.showCreateEventsModal && <EventsModal />}
    </>
}

export default Events