import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import SideNav from "../../components/sidenav/SideNav"
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { userActions, utilityActions } from "../../_store";

const Layout = (props) => {

    const location = useLocation()

    const dispatch = useDispatch()
    
    const { loading: loading, activePage: activePage } =  useSelector(state => state.utility)
    const {user: userDetails} = useSelector(state => state.user)

    const mapping = {
        '/members': 'Manage Students',
        '/users': 'Manage Families',
        '/schedule': 'Schedule',
        '/events': 'Manage Events',
        '/subscription': 'Manage Subscription',
        '/payments': 'Payments',
        '/billings': 'Billings',
        '/accounts': 'My Account',
        '/settings': 'Settings'
    }
    
    useEffect(() => {
        const path = location.pathname
        const currentPage = mapping[path] ? mapping[path] : "Home"
        if(activePage !== currentPage) {
            dispatch(utilityActions.activePage(currentPage))
        }
        if(!userDetails.id)
            dispatch(userActions.getUser())
        return () => {}
    }, [location])

    return <div className="h-screen text-[0.8rem]">
        {loading ? <Loader /> : <div/>}
        <div className="flex flex-col h-[100%] sm:flex-row sm:relative">
            <SideNav/>
            <div className="bg-[#f6f7f8] w-full overflow-scroll h-full">
                <div className={`h-[95%] flex flex-col`}>
                    <div className="flex-1">
                        {props.children}
                    </div>
                </div>
            </div>
            
        </div>
    </div>
}

export default Layout