import { useEffect, useState } from "react";
import SectionCard from "../../../components/section-card/SectionCard"
import { TfiReceipt } from "react-icons/tfi"
import { Label } from 'flowbite-react';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_store";
import { useForm, reset, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { utilities } from "../../../_helpers";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";

const GeneralInfo = (props) => {
    
    const dispatch = useDispatch()
    // const {user: userDetails} = useSelector(state => state.user)
    const [userDetails, setUserDetails] = useState({})

    useEffect(() => {
        if(props.userDetails)
            setUserDetails(props.userDetails)
    }, [props.userDetails])

    const [editable, setEditable] = useState(false); 

    // form validation rules 
    const validationSchema = Yup.object().shape({
        dob: Yup.date().required('Require proper format'),
    });

    const defaultValue = {
        name: userDetails.first_name + ' ' + userDetails.last_name,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        phoneno: userDetails.phoneno,
        dob: userDetails.userDetail ? new Date(userDetails.userDetail.dob) : userDetails.userDetail,
        address: userDetails.userDetail ? userDetails.userDetail.address : userDetails.userDetail,
        gender: userDetails.userDetail ? userDetails.userDetail.gender : userDetails.userDetail,
        city: userDetails.userDetail ? userDetails.userDetail.city : userDetails.userDetail,
        state: userDetails.userDetail ? userDetails.userDetail.state : userDetails.userDetail,
        zipcode: userDetails.userDetail ? userDetails.userDetail.zipcode : userDetails.userDetail,
        employment: userDetails.userDetail ? userDetails.userDetail.employment : userDetails.userDetail,
    }

    useEffect(() => {
        reset(defaultValue)
    }, [userDetails]);

    const formOptions = { resolver: yupResolver(validationSchema), defaultValues: defaultValue };


    const { register, handleSubmit, formState, reset, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = async (data) => {
        console.log('Submitted')
        if(data.dob) {
            data.dob = new Date(data.dob).toISOString().split('T')[0]
        }
        data.id = userDetails.id
        console.log(data)
        const resp = await dispatch(userActions.updateUser({ data, action: props.updateAction }))
        if (resp.meta.requestStatus === 'fulfilled') {
            setEditable(false)
        }
    }

    const disabled_theme = 'border-none w-full bg-gray-50 border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg cursor-not-allowed'

    const enabled_theme = 'w-full bg-gray-50 bg-white border-gray-300 text-gray-900 focus:border-gray-300 focus:ring-gray-300 p-2.5 text-sm rounded-lg'

    const childProps = {
        editable: editable,
        setEditable: () =>  { 
            reset()
            setEditable(!editable)
        },
        saveAction: handleSubmit(onSubmit),
        headerIcon: <TfiReceipt/>,
        title: "General Information",
        isSubmitting: isSubmitting
    }

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };

    const years = range(1950, getYear(new Date()) + 1);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return <SectionCard {...childProps} showEditable={true}>
            <div className="py-4">
                <form >
                    <div className="grid md:grid-cols-5 gap-4 mb-4">
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="First Name" />
                            </div>
                            <input {...register('first_name')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`}  type="text" placeholder="First Name"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Last Name" />
                            </div>
                            <input {...register('last_name')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="Last Name"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Date of Birth" />
                            </div>
                            <Controller
                                control={control}
                                    name='dob'
                                    render={({ field }) => (
                                    <DatePicker
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"} </button>
                                                <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map((option) => ( <option key={option} value={option}> {option} </option>))}
                                                </select>
                                                <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) }>
                                                    {months.map((option) => ( <option key={option} value={option}> {option} </option> ))}
                                                </select>
                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                            </div>
                                        )}
                                        showIcon
                                        icon={<AiOutlineCalendar className='absolute right-0 top-1 !text-lg' />}
                                        disabled={!editable} className={`!p-2.5 ${editable ? enabled_theme : disabled_theme}`}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText='MM/DD/YYYY'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Age"/>
                            </div>
                            <input disabled={true} className={`${disabled_theme}`} type="number" value={utilities.getAge(defaultValue.dob)} />
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Gender" />
                            </div>
                            <select {...register('gender')}  id="gender" disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} required >
                                <option value={'Male'}> Male </option>
                                <option value={'Female'}> Female </option>
                                <option value={'Others'}> Others </option>
                            </select>
                        </div>    
                    </div>
                    <div className="grid md:grid-cols-4 gap-4  mb-4">
                        <div className="col-span-2">
                            <div className="block">
                                <Label htmlFor="small" value="Address" />
                            </div>
                            <textarea {...register('address')}  disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} rows={4} type="text" placeholder="Address"/>
                        </div>
                        <div className="col-span-2">
                            <div className="grid grid-cols-2 gap-4 mb-2">
                                <div>
                                    <div className="block">
                                        <Label htmlFor="small" value="State" />
                                    </div>
                                    <input {...register('state')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="State"/>
                                </div>
                                <div>
                                    <div className="block">
                                        <Label htmlFor="small" value="City" />
                                    </div>
                                    <input {...register('city')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`}  type="text" placeholder="City"/>
                                </div>
                            </div>
                            <div>
                                    <div className="block">
                                        <Label htmlFor="small" value="Zip" />
                                    </div>
                                    <input {...register('zipcode')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="text" placeholder="Zip"/>
                                </div>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 gap-4">
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Email Id" />
                            </div>
                            <input {...register('email')} disabled={true} className={`${disabled_theme}`} type="email" placeholder="Email Id"/>
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Phone number"/>
                            </div>
                            <input {...register('phoneno')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="text" />
                        </div>
                        <div>
                            <div className="block">
                                <Label htmlFor="small" value="Employer & Position"/>
                            </div>
                            <input {...register('employment')} disabled={!editable} className={`${editable ? enabled_theme : disabled_theme}`} type="text" />
                        </div>
                    </div>
                </form>
            </div>     
        </SectionCard>
}

export default GeneralInfo